import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IfCan from 'containers/IfCan';
import styles from './styles.module.css';
import { Row, Col } from 'react-bootstrap';

const LeftNav = (props) => {
  const {
    activeMenuItem,
    changeActiveMenu,
    navMap,
    primaryHeader,
    secondaryHeader,
    testIdPrefix,
  } = props;

  return (
    <Col className={styles.root} data-cy-id={`${testIdPrefix}-LeftNav`}>
      <Row className={styles.leftPanelContainer} noGutters>
        <Col>
          <Row>
            <Col
              data-cy-class="LeftNav-PrimaryHeader"
              className={styles.flightNumber}
            >
              {primaryHeader}
            </Col>
          </Row>
          <Row>
            <Col
              data-cy-class="LeftNav-SecondaryHeader"
              className={styles.text}
            >
              {secondaryHeader}
            </Col>
          </Row>
        </Col>
      </Row>
      {Object.keys(navMap).map((key, i) => {
        const menu = navMap[key];
        if (!menu.length) {
          return null;
        }
        return (
          <Row
            data-cy-class="LeftNav-MenuSection"
            className={styles.leftPanelContainer}
            key={i}
          >
            <Col>
              <Row>
                <Col
                  data-cy-class="LeftNav-MenuSection-Header"
                  className={styles.menuTitle}
                >
                  {' '}
                  {key}{' '}
                </Col>
              </Row>
              {menu?.map((item, index) => {
                const isActive = item.id === activeMenuItem;
                return (
                  <IfCan forbiddenRoles={item.forbiddenRoles} key={index}>
                    <Row
                      onClick={() => changeActiveMenu(item.id)}
                      className={styles.menuItemRow}
                      data-cy-id={`${testIdPrefix}-LeftNav-${item.id}Button`}
                      style={{ display: item.hide ? 'none' : 'flex' }}
                    >
                      <Col
                        className={classnames(
                          styles.menuItem,
                          isActive ? styles.menuItemActive : '',
                        )}
                      >
                        {item.display}
                      </Col>
                    </Row>
                  </IfCan>
                );
              })}
            </Col>
          </Row>
        );
      })}
    </Col>
  );
};

LeftNav.prototype = {
  activeMenuItem: PropTypes.string,
  changeActiveMenu: PropTypes.func,
  navMap: PropTypes.object.isRequired,
  primaryHeader: PropTypes.string,
  secondaryHeader: PropTypes.string,
  testIdPrefix: PropTypes.string.isRequired,
};

export default LeftNav;
