import { UPDATE_CURRENT_TIME, UPDATE_NOTIFICATION_TIME } from 'actions';

export function currentTime(state = {}, action) {
  switch (action.type) {
    case UPDATE_CURRENT_TIME:
      return action.currentTime;
    default:
      return state;
  }
}

export function notificationSeenTime(state = {}, action) {
  switch (action.type) {
    case UPDATE_NOTIFICATION_TIME:
      return action.notificationSeenTime;
    default:
      return state;
  }
}
