import React, { useState } from 'react';

import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  SELECT_OPTION_ALL,
  DEFAULT_OPTION,
} from 'constants';

import { Row, Col, Button } from 'react-bootstrap';
import Select from 'components/inputs/Select';

import styles from './styles.module.css';
import ReportSummaryOverlay from 'components/overlays/ReportSummaryOverlay';
import { ReportFactory } from 'classes';

const idPrefix = 'ShipReport';
const Ship = (props) => {
  const { aircraftList, aircraftId } = props;

  const aircraft = aircraftList?.find((ac) => ac.id === aircraftId);

  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);
  const [tailOption, setTailOption] = useState(aircraft ? aircraft.tail : '');
  const [overlayOpen, setOverlayOpen] = useState(!aircraft ? false : true);
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [selectedAircraftId, setselectedAircraftId] = useState(
    aircraftId ? aircraftId : '',
  );

  const tails = [DEFAULT_OPTION]
    .concat(
      !aircraftList
        ? []
        : (!fleetOption
            ? aircraftList
            : aircraftList.filter(
                (aircraft) => aircraft?.aircraft_type?.id === fleetOption,
              )
          ).map((aircraft) => {
            return {
              value: aircraft.id,
              name: aircraft.tail,
            };
          }),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <Row className={styles.root} noGutters>
        <Col>
          <Row className={styles.title} noGutters>
            <Col data-cy-id={`${idPrefix}-ScreenHeader`} className="title_1">
              {' '}
              Ship report{' '}
            </Col>
            <Col md="auto" className={styles.note}>
              Note: Data is available for previous 30 days.
            </Col>
          </Row>
          <Row
            className={styles.filterRow}
            data-cy-id={`${idPrefix}-Filters`}
            noGutters
          >
            <Col>
              <Row>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-Fleet`}
                    data-cy-id={`${idPrefix}-FiltersPanel-FleetSelect`}
                    label="Fleet: "
                    options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
                    value={fleetOption}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setFleetOption(value);
                      setTailOption('');
                      setselectedAircraftId('');
                    }}
                  />
                </Col>
                <Col md="auto">
                  <Select
                    idPrefix={`${idPrefix}-Tail`}
                    data-cy-id={`${idPrefix}-FiltersPanel-TailSelect`}
                    label="Tail: "
                    options={tails}
                    value={selectedAircraftId}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      const selectedAircraft = aircraftList.find(
                        (ac) => ac.id === value,
                      );
                      setTailOption(
                        !selectedAircraft ? '' : selectedAircraft.tail,
                      );
                      setselectedAircraftId(value);
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col md="auto">
              <Button
                className="button_foc"
                data-cy-id={`${idPrefix}-CreateReportButton`}
                disabled={!tailOption}
                onClick={() => setOverlayOpen(true)}
              >
                Create report
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ReportSummaryOverlay
        title="Ship report"
        data={
          <>
            <Row>
              <Col> Fleet </Col>
              <Col>
                {fleetOption === ''
                  ? 'All'
                  : AIRCRAFT_TYPE_OPTIONS_W_ALL[fleetOption].name}
              </Col>
            </Row>
            <Row>
              <Col> Tail </Col>
              <Col> {tailOption} </Col>
            </Row>
          </>
        }
        isOpen={overlayOpen}
        onCancel={() => setOverlayOpen(false)}
        loading={messageOverlayOpen}
        onDone={async () => {
          setMessageOverlayOpen(true);
          await ReportFactory.getShipReport(selectedAircraftId);
          setMessageOverlayOpen(false);
          setOverlayOpen(false);
        }}
      />
    </>
  );
};

export default Ship;
