import { UPDATE_CONFIGURATION, SET_MAINTENANCE_MODE } from 'actions';

export function configuration(state = {}, action) {
  switch (action.type) {
    case SET_MAINTENANCE_MODE:
      const value =
        action.enabled === 'true' || action.enabled === 'false'
          ? action.enabled === 'true'
          : action.enabled;
      return Object.assign({}, state, { maintenance_mode: value });
    case UPDATE_CONFIGURATION:
      // convert each string to corresponding boolean value
      Object.entries(action.configuration).forEach(([key, value]) => {
        if (value === 'true' || value === 'false') {
          action.configuration[key] = value === 'true';
        }
      });
      return Object.assign({}, state, action.configuration);
    default:
      return state;
  }
}
