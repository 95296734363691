import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { getHealthColorFromAircraftHealth } from 'utils';

import { Pie, PieChart, Sector } from 'recharts';

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    // midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    // percent,
    // value
  } = props;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill="#F2F2F2"
        fontWeight="bold"
        fontSize="16"
      >
        {payload.value}%
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

// unable to get the activeShape rendering to work without a class component
export default class HealthGauge extends PureComponent {
  state = {
    activeIndex: 0,
  };

  render() {
    const { healthScore } = this.props;
    const pieData = [
      {
        name: 'Healthy',
        value: Math.round(healthScore),
        fill: getHealthColorFromAircraftHealth(healthScore),
      },
      {
        name: 'Unhealthy',
        value: Math.round(100 - healthScore),
        fill: '#F2F2F2',
      },
    ];
    return (
      <PieChart width={80} height={80}>
        <Pie
          activeIndex={this.state.activeIndex}
          startAngle={450}
          endAngle={90}
          activeShape={renderActiveShape}
          data={pieData}
          cx="50%"
          cy="50%"
          innerRadius={30}
          outerRadius={40}
          stroke="#272727"
          dataKey="value"
          fill="#0088FE"
          isAnimationActive={false}
        ></Pie>
      </PieChart>
    );
  }
}

HealthGauge.propTypes = {
  healthScore: PropTypes.number,
};
