import React, { useState } from 'react';
import classnames from 'classnames';

import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  SELECT_OPTION_ALL,
  PATHS,
} from 'constants';

import { Row, Col, Button } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const idPrefix = 'LRUFaultOverview';

const columnDefinitions = [
  {
    name: 'Tail',
    key: 'tail',
    size: 'xsmall',
    sortable: true,
    dataCyClassName: 'LRUFaults-TotalFaultsPerFlightTableColumn-Tail',
  },
  {
    name: 'Fleet',
    key: 'fleet',
    size: 'small',
    dataCyClassName: 'LRUFaults-TotalFaultsPerFlightTableColumn-Fleet',
  },
  {
    name: 'Last flight',
    key: 'last_flight',
    size: 'xsmall',
    sortable: true,
    dataCyClassName: 'LRUFaults-TotalFaultsPerFlightTableColumn-LastFlight',
  },
  {
    name: '7 day average',
    key: 'avg',
    size: 'xsmall',
    sortable: true,
    dataCyClassName: 'LRUFaults-TotalFaultsPerFlightTableColumn-7DayAverage',
  },
  {
    name: 'Current health',
    key: 'current_health',
    size: 'xsmall',
    sortable: true,
    dataCyClassName: 'LRUFaults-TotalFaultsPerFlightTableColumn-CurrentHealth',
  },
];

const getStatusClass = (status) => {
  switch (status) {
    case '1':
      return styles.flightOperational;
    case '2':
      return styles.flightDegraded;
    case '3':
      return styles.flightUnhealthy;
    case '4':
    default:
      return styles.flightOffline;
  }
};

const getHealthData = (status, isConnected, healthPercentage) => {
  return (
    <Row noGutters>
      <Col
        data-cy-id="LRUFaults-FleetHealthList-statusIcon"
        md="auto"
        className={classnames(styles.statusIcon, getStatusClass(status))}
      >
        {!isConnected ? <div className={styles.disconnectedLine} /> : null}
      </Col>
      <Col md="auto" className="ml-2">
        {healthPercentage ? healthPercentage + '%' : 'NA'}
      </Col>
    </Row>
  );
};

const Overview = (props) => {
  const { aircraftList, onFleetRowClick, push } = props;
  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);

  const onSelectChange = ({ target: { value } }) => {
    setFleetOption(value);
  };

  const data = aircraftList
    ? aircraftList
        .filter(
          (ac) =>
            fleetOption === SELECT_OPTION_ALL.value ||
            ac.aircraft_type.id === fleetOption,
        )
        .map((ac) => {
          return {
            id: ac.id,
            tail: ac.tail,
            fleet: ac.aircraft_type.value.name,
            last_flight: ac.stats.fault_count_current_flight,
            avg: Math.round(ac.stats.fault_count_seven_day_average),
            current_health: {
              display: getHealthData(ac.status?.id, true, ac.health_score),
              sort: ac.health_score,
            },
          };
        })
    : [];

  return (
    <Row className={styles.root} noGutters>
      <Col>
        <Row className={styles.title} noGutters>
          <Col data-cy-id="LRUFaults-Header" className="title_1">
            Fleet health list
          </Col>
          <Col md="auto">
            <Button
              className="button_reg"
              data-cy-id="LRU-LRUFaultsReportButton"
              onClick={() => push(PATHS.reportLruFaults)}
            >
              LRUs & Faults reports
            </Button>
          </Col>
        </Row>
        <Row className={styles.fleetSelectionContainer} noGutters>
          <Col md="auto">
            <Select
              idPrefix={idPrefix}
              label="Fleet: "
              onChange={onSelectChange}
              options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
              value={fleetOption}
              data-cy-id="LRUFaults-FleetSelect"
            />
          </Col>
        </Row>
        <Row className={styles.dataContainer} noGutters>
          <Col className="h-100">
            <Row className={styles.tableContainer} noGutters>
              <Col className="h-100">
                <SkyPulseTable
                  columns={columnDefinitions}
                  rows={data}
                  title="Ship - Total faults per flight"
                  defaultSort="last_flight"
                  defaultSortState="descending"
                  onRowClick={(id) => {
                    onFleetRowClick(id);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Overview;
