import { v4 as uuid } from 'uuid';
import { initUserManager } from 'libs/oidc';

const sessionId = uuid();

const config = {
  amplify: {
    API: {
      endpoints: [
        {
          name: 'skypulse-api',
          endpoint: process.env.REACT_APP_API_ENDPOINT,
          custom_header: async () => {
            const userManager = initUserManager(config.globalAuth);
            const user = await userManager.getUser();
            return {
              Authorization: `Bearer ${config.features.globalAuth ? user.access_token : 'abc123'}`,
              'Content-Type': 'application/json;charset=UTF-8',
              'x-api-key': process.env.REACT_APP_API_KEY,
              'x-session-id': sessionId,
            };
          },
        },
      ],
    },
  },
  app: {
    refreshInterval: 60 * 1000,
  },
  environment: {
    name: process.env.REACT_APP_GLOBAL_ENVIRONMENT,
    offline:
      process.env.REACT_APP_OFFLINE &&
      (process.env.REACT_APP_OFFLINE.toLowerCase() === 'true' ||
        process.env.REACT_APP_OFFLINE === '1'),
  },
  features: {
    globalAuth:
      process.env.REACT_APP_FEATURE_GLOBAL_AUTH &&
      (process.env.REACT_APP_FEATURE_GLOBAL_AUTH.toLowerCase() === 'true' ||
        process.env.REACT_APP_FEATURE_GLOBAL_AUTH === '1'),
  },
  globalAuth: {
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    clientId: process.env.REACT_APP_OIDC_CLIENT_ID,
    redirectUri: window.location.origin,
    silentRedirectUri: window.location.origin,
    responseType: 'code',
    scope: 'openid profile',
    autoSignIn: true,
  },
  services: {},
  versions: {
    'skypulse-ui': process.env.REACT_APP_VERSION_UI || 'dev',
    '@dfp/common': process.env.REACT_APP_VERSION_DFP_COMMON,
    '@skypulse/common': process.env.REACT_APP_VERSION_SKYPULSE_COMMON,
  },
  google: {
    maps: {
      key: process.env.REACT_APP_WEBAPP_GOOGLE_MAPS_KEY,
      style: [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#212121',
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#212121',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'administrative.country',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd',
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'poi.business',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#181818',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#1b1b1b',
            },
          ],
        },
        {
          featureType: 'road',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#2c2c2c',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#8a8a8a',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#373737',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#3c3c3c',
            },
          ],
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry',
          stylers: [
            {
              color: '#4e4e4e',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#3d3d3d',
            },
          ],
        },
      ],
    },
  },
};
export default config;
