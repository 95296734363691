import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Row, Col, Form } from 'react-bootstrap';
import styles from './styles.module.css';

const Select = (props) => {
  const {
    classname,
    containerClassName,
    defaultOption,
    disabled,
    error,
    helpText,
    idPrefix,
    inputRef,
    label,
    labelClassName,
    name,
    onChange,
    options,
    value,
    selectClassname,
    ...passProps
  } = props;

  const id = `${idPrefix}-Select-Input`;

  return (
    <Form.Group
      controlId={id}
      className={classnames(styles.root, containerClassName)}
    >
      <Row className={styles.container} noGutters>
        {!label ? null : (
          <Form.Label
            column
            className={`label ${labelClassName} ${styles.label} ${error ? 'labelError' : null}`}
          >
            {label}
          </Form.Label>
        )}
        <Col className={styles.root}>
          <Form.Control
            as="select"
            name={name}
            value={value}
            onChange={onChange}
            isInvalid={error}
            disabled={disabled}
            className={classnames(selectClassname, classname, styles.input)}
            ref={inputRef}
            {...passProps}
          >
            {!defaultOption ? null : <option>{defaultOption}</option>}
            {options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.name}
                </option>
              );
            })}
          </Form.Control>
          {!error ? null : (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          )}
          {!error && helpText ? (
            <Row>
              <Col>
                <Form.Text> {helpText} </Form.Text>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </Form.Group>
  );
};

Select.propTypes = {
  classname: PropTypes.string,
  containerClassName: PropTypes.string,
  defaultOption: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helpText: PropTypes.string,
  idPrefix: PropTypes.string.isRequired,
  inputRef: PropTypes.object,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectClassname: PropTypes.object,
};

export default Select;
