import { configurationGET } from 'libs/api';
import { Logger } from 'classes/Logger';

export const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION';
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE';

export function updateConfiguration() {
  return async (dispatch) => {
    const {
      data: { result: configuration },
    } = await configurationGET();

    Logger.setLogLevel(configuration.log_level);

    dispatch({
      type: UPDATE_CONFIGURATION,
      configuration,
    });
  };
}

export function patchConfiguration(configuration) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CONFIGURATION,
      configuration,
    });
  };
}

export function setMaintenanceMode(enabled) {
  return async (dispatch) => {
    dispatch({
      type: SET_MAINTENANCE_MODE,
      enabled,
    });
  };
}
