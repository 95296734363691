import React, { useState, useEffect } from 'react';
import {
  AIRCRAFT_TYPE_A220100,
  AIRCRAFT_TYPE_A220300,
  DEFAULT_OPTION,
} from 'constants';

import { a220LogListGET, a220LogsPOST, a220LogsGET } from 'libs/api';

import { Row, Col, Button } from 'react-bootstrap';
import DatePicker from 'components/inputs/DatePicker';
import Select from 'components/inputs/Select';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const idPrefix = 'A220Logs';
const numberOfTries = 12;

const A220Logs = (props) => {
  const { aircraftList } = props;

  const [isLoading, setIsLoading] = useState(false);

  // log list filters
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [selectedAircraftId, setSelectedAircraftId] = useState('');
  const [logfileDt, setLogfileDt] = useState(null);
  const [calltoFetchLogs, setCalltoFetchLogs] = useState(false);

  // logfile lists
  const [availableLogfiles, setAvailableLogfiles] = useState([]);

  const columnDefinitions = [
    {
      name: 'Filename',
      key: 'filename',
      size: 'large',
      sortable: true,
    },
  ];

  useEffect(() => {
    const filteredOptions = [DEFAULT_OPTION]
      .concat(
        !aircraftList.length
          ? []
          : aircraftList
              .filter((aircraft) => {
                return (
                  aircraft.aircraft_type.id === AIRCRAFT_TYPE_A220100.id ||
                  aircraft.aircraft_type.id === AIRCRAFT_TYPE_A220300.id
                );
              })
              .map((aircraft) => {
                return {
                  value: aircraft.id,
                  name: `${aircraft.tail} (${aircraft.registration || 'NA'})`,
                };
              }),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
    setAircraftOptions(filteredOptions);
  }, [aircraftList]);

  const handleLogFetch = async () => {
    try {
      setIsLoading(true);
      setCalltoFetchLogs(true);
      const response = await a220LogListGET(
        selectedAircraftId,
        logfileDt / 1000,
      ).catch((e) => {
        setIsLoading(false);
      });

      const {
        data: {
          result: { logs: availableLogfiles },
        },
      } = response;

      const rowData = availableLogfiles.map((logfile, index) => {
        return {
          id: logfile,
          filename: (
            <div
              key={index}
              className={'skypulsetable_toggle_button'}
              data-clickable-id={logfile}
            >
              {logfile}
            </div>
          ),
        };
      });

      setAvailableLogfiles(rowData);
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogsGet = (exportKey) => {
    return new Promise((resolve) => {
      let cnt = 0;
      const refreshIntervalId = setInterval(async () => {
        cnt++;
        return await a220LogsGET(exportKey)
          .then((result) => {
            setIsLoading(false);
            clearInterval(refreshIntervalId);
            resolve(result);
          })
          .catch(() => {
            setIsLoading(false);
            if (cnt > numberOfTries) {
              clearInterval(refreshIntervalId);
            }
          });
      }, 5000);
    });
  };

  const handleLogDownload = async (downloadAll, selectedLogFile = null) => {
    try {
      setIsLoading(true);

      let response;
      if (downloadAll) {
        const { data } = await a220LogsPOST(
          selectedAircraftId,
          logfileDt / 1000,
          'all',
        ).catch(() => {
          setIsLoading(false);
        });
        const exportKey = data?.result;
        response = await handleLogsGet(exportKey);
      } else {
        response = await a220LogsPOST(
          selectedAircraftId,
          logfileDt / 1000,
          selectedLogFile,
        ).catch(() => {
          setIsLoading(false);
        });
      }
      const {
        data: { result: secureUrl },
      } = response;

      const anchor = document.createElement('a');
      anchor.setAttribute('href', secureUrl);
      anchor.setAttribute('target', '_blank');
      anchor.click();
    } catch (e) {
      console.warn(e);
    } finally {
      // cleanup
      setIsLoading(false);
    }
  };

  return (
    <>
      <Row className={styles.root} noGutters>
        <Col>
          <Row className={styles.title} noGutters>
            <Col className="title_1">A220 Logs</Col>
            <Col md="auto" className={styles.note}>
              Note: logs can be fetched on a per-day basis and downloaded in
              batches
            </Col>
          </Row>
          <Row
            className={styles.filterRow}
            data-cy-id={`${idPrefix}-Filters`}
            noGutters
          >
            <Col>
              <Row className={styles.filterContainer}>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-Tail`}
                    label="Tail: "
                    options={aircraftOptions}
                    value={selectedAircraftId}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setSelectedAircraftId(value);
                    }}
                  />
                </Col>
                <Col md="auto">
                  <DatePicker
                    idPrefix={`${idPrefix}-Date`}
                    label="Logfile Date: "
                    value={logfileDt}
                    onChange={(date) => {
                      setLogfileDt(parseInt(date, 10));
                    }}
                    maxDate={Date.now()}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="auto">
              <Button
                className="button_foc"
                data-cy-id={`${idPrefix}-LoadLogsButton`}
                disabled={isLoading || !selectedAircraftId || !logfileDt}
                onClick={handleLogFetch}
              >
                Fetch Available Logs
              </Button>
            </Col>
          </Row>
          {!calltoFetchLogs ? null : (
            <Row className={styles.dataContainer} noGutters>
              <Col className="h-100">
                <SkyPulseTable
                  title={
                    <Button
                      className="button_foc"
                      data-cy-id={`${idPrefix}-DownloadLogsButton`}
                      disabled={isLoading}
                      onClick={() => handleLogDownload(true)}
                    >
                      Download All Logs
                    </Button>
                  }
                  titleClassName={styles.titleContainer}
                  columns={columnDefinitions}
                  rows={availableLogfiles}
                  isLoading={isLoading}
                  showHeaders={true}
                  onRowClick={(id, logFileId = null) => {
                    if (id === 'root' && !logFileId) {
                      return;
                    }
                    handleLogDownload(false, logFileId);
                  }}
                  className={styles.logTable}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default A220Logs;
