/* eslint-disable no-use-before-define */

import { DFPSingleton } from '@dfp/common';
import {
  reportAvailabilityGET,
  reportShipGET,
  reportFlightGET,
  reportLrusAndFaultsGET,
  reportLruDetailGET,
  reportUsageGET,
  reportConfigurableFeaturesGET,
} from 'libs/api';

export class ReportFactory extends DFPSingleton {
  static async getAvailabilityReport(atid, dt) {
    const response = await reportAvailabilityGET(atid, dt);
    downloadReport(response);
  }

  static async getShipReport(aircraftId) {
    const response = await reportShipGET(aircraftId);
    downloadReport(response);
  }

  static async getFlightReport(flightId) {
    const response = await reportFlightGET(flightId);
    downloadReport(response);
  }

  static async getLruReport(aircraftTypeId) {
    const response = await reportLrusAndFaultsGET(aircraftTypeId);
    downloadReport(response);
  }

  static async getLruDetailReport(aircraftId, lruTypeId, position) {
    const response = await reportLruDetailGET(aircraftId, lruTypeId, position);
    downloadReport(response);
  }

  static async getUsageReport(arrDt) {
    const response = await reportUsageGET(undefined, undefined, arrDt);
    downloadReport(response);
  }

  static async getUsageReportByAircraftType(aircraftTypeId, arrDt) {
    const response = await reportUsageGET(aircraftTypeId, undefined, arrDt);
    downloadReport(response);
  }

  static async getUsageReportByAircraftId(aircraftId, arrDt) {
    const response = await reportUsageGET(undefined, aircraftId, arrDt);
    downloadReport(response);
  }

  static async getConfigurableFeaturesReport(atid) {
    const response = await reportConfigurableFeaturesGET(atid);
    downloadReport(response);
  }
}

async function downloadReport(response) {
  let { data } = response;
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    response.headers['content-disposition'].split('filename=')[1].split(';')[0],
  );
  document.body.appendChild(link);
  link.click();
}
