import React from 'react';
import PropTypes from 'prop-types';

import { Aircraft, LRU } from 'models';

import { Row, Col } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const overviewCol = [
  {
    name: 'Tail',
    key: 'tail',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Tail',
  },
  {
    name: 'LRU',
    key: 'lru',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Lru',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Pos',
  },
  {
    name: 'ITU ROM',
    key: 'itu_rom',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-ItuRom',
  },
  {
    name: 'ITU ISD',
    key: 'itu_isd',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-ItuIsd',
  },
  {
    name: 'ITU FSS',
    key: 'itu_fss',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-ItuFss',
  },
  {
    name: 'Map SW part',
    key: 'map_sw_part',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-MapSwPart',
  },
  {
    name: 'Map assets SW part',
    key: 'map_assets_sw_part',
    size: 'small',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-MapAssetsSwPart',
  },
];

const CrewPanels = (props) => {
  const { lruData, aircraft } = props;

  const latestLRUHistory = lruData.getLatestHistory();
  let itu_fss;
  let itu_isd;
  let map_sw_part;
  let map_assets_sw_part;
  let itu_rom;
  if (latestLRUHistory?.software_version) {
    const { FSS, ISD, MAP, MASA, ROM } = latestLRUHistory.software_version;
    itu_fss = FSS;
    itu_isd = ISD;
    itu_rom = ROM;
    map_assets_sw_part = MASA;
    map_sw_part = MAP;
  }

  const overviewData = [
    {
      tail: aircraft?.tail,
      lru: lruData.lru_type.value,
      pos: lruData.position,
      itu_rom: itu_rom,
      itu_isd: itu_isd,
      itu_fss: itu_fss,
      map_sw_part: map_sw_part,
      map_assets_sw_part: map_assets_sw_part,
    },
  ];

  return (
    <Row
      data-cy-id="LruDetailsOverlay-overviewContainer"
      className={styles.overviewContainer}
      noGutters
    >
      <Col>
        <Row>
          <Col
            data-cy-id="LruDetailsOverlay-overviewTableTitle"
            className={styles.title}
          >
            Overview
          </Col>
        </Row>
        <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable columns={overviewCol} rows={overviewData} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CrewPanels.propTypes = {
  lruData: PropTypes.instanceOf(LRU).isRequired,
  aircraft: PropTypes.instanceOf(Aircraft),
};

export default CrewPanels;
