import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import styles from './styles.module.css';
import ButtonItem from 'components/inputs/ButtonItem';

const ButtonList = (props) => {
  const {
    arrangementClassName,
    classname,
    disabled,
    error,
    idPrefix,
    inline,
    label,
    labelClassName,
    name,
    onChange,
    options,
    type,
    value,
    testIdPrefix,
  } = props;

  const id = `${idPrefix}-ButtonList-Container`;

  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (e) => {
    const { value } = e.target;

    let newValue = selectedValue;
    switch (type) {
      case 'checkbox':
        const index = selectedValue.findIndex((sv) => {
          return sv === value || sv === parseInt(value, 10) || sv.id === value;
        });
        if (index === -1) {
          newValue.push(value);
        } else {
          newValue.splice(index, 1);
        }
        break;
      default:
        newValue = value;
        break;
    }

    setSelectedValue(newValue);

    const newEvent = Object.assign(
      {},
      {
        target: {
          id,
          name,
          value: newValue,
        },
      },
    );
    onChange(newEvent);
  };

  const isChecked = (value) => {
    return (
      selectedValue &&
      value &&
      (selectedValue === value ||
        (Array.isArray(selectedValue) && selectedValue.includes(value)) ||
        selectedValue === parseInt(value, 10) ||
        (Array.isArray(selectedValue) &&
          selectedValue.includes(parseInt(value, 10))) ||
        (Array.isArray(selectedValue) &&
          selectedValue.some((sv) => sv.id === value)))
    );
  };

  return (
    <Col>
      <Row className={styles.title}>{label}</Row>
      <Row>
        <Col id={id} className={arrangementClassName}>
          {options.map((option, index) => {
            return (
              <ButtonItem
                key={index}
                value={option.value}
                idPrefix={`${idPrefix}${index}`}
                name={name}
                label={option.name}
                onChange={handleChange}
                type={type}
                error={error}
                disabled={disabled}
                isChecked={isChecked(option.value)}
                inline={inline}
                classname={classname}
                labelClassName={labelClassName}
                data-cy-id={`${testIdPrefix}-${option.value}Input`}
              />
            );
          })}
        </Col>
      </Row>
    </Col>
  );
};

ButtonList.propTypes = {
  arrangementClassName: PropTypes.string,
  classname: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  idPrefix: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default ButtonList;
