import React from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';

import { Bar, BarChart, YAxis } from 'recharts';

const HealthHistogram = (props) => {
  const { histogramData } = props;

  return (
    <BarChart
      className={styles.barchart}
      margin={{ top: 2, right: 2, bottom: 2, left: 2 }}
      width={150}
      height={80}
      data={histogramData}
      barCategoryGap="-20%"
      stroke="none"
    >
      <YAxis hide type="number" domain={[0, 100]} />
      <Bar dataKey="value" isAnimationActive={false} />
    </BarChart>
  );
};

HealthHistogram.propTypes = {
  histogramData: PropTypes.array,
};

export default HealthHistogram;
