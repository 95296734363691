import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Aircraft } from 'models';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import styles from './styles.module.css';

const AircraftCluster = (props) => {
  const {
    aircraftList,
    index,
    healthStatus,
    onRowClick,
    pointCount = 0,
    showTooltip,
    setTooltipIndex,
  } = props;

  const target = useRef(null);

  const per = {
    1: pointCount < 1 ? 0 : (healthStatus[1] * 100) / pointCount,
    2: pointCount < 1 ? 0 : (healthStatus[2] * 100) / pointCount,
    3: pointCount < 1 ? 0 : (healthStatus[3] * 100) / pointCount,
    4: pointCount < 1 ? 0 : (healthStatus[4] * 100) / pointCount,
  };

  const gradient = `conic-gradient(
    var(--CL_STATUS_OPERATIONAL) 0% ${per[1]}%,
    var(--CL_STATUS_DEGRADED) ${per[1]}% ${per[1] + per[2]}%,
    var(--CL_STATUS_UNHEALTHY) ${per[1] + per[2]}% ${per[1] + per[2] + per[3]}%,
    var(--CL_STATUS_OFFLINE) ${per[1] + per[2] + per[3]}% ${per[1] + per[2] + per[3] + per[4]}%)`;

  const getStatusClass = (status) => {
    switch (status ? status.id : '4') {
      case '2':
        return styles.degraded;
      case '3':
        return styles.unhealthy;
      case '4':
        return styles.offline;
      default:
        return styles.operational;
    }
  };

  return (
    <div className={classnames(styles.root)} key={index}>
      <div className={styles.container}>
        <div
          data-cy-class="MapPanel-AircraftCluster"
          className={styles.clusterWrapper}
          style={{ background: gradient }}
        >
          <Button
            className={styles.cluster}
            varient="light"
            ref={target}
            onClick={() => setTooltipIndex(index)}
          >
            <span className={styles.label}>{pointCount}</span>
          </Button>
          <Overlay target={target.current} show={showTooltip} placement="right">
            {(props) => (
              <Tooltip className={styles.tooltip} {...props}>
                <div className={styles.tooltipContainer}>
                  {aircraftList.map((aircraft, index) => {
                    const { id, status, is_connected, tail } = aircraft;
                    const statusClass = getStatusClass(status);
                    return (
                      <div
                        key={index}
                        className={styles.tooltipItem}
                        onClick={() => onRowClick(id)}
                      >
                        <div className={styles.container}>
                          <div
                            className={classnames(styles.icon, statusClass)}
                          />
                          {is_connected ? null : (
                            <div className={styles.disconnectedLine} />
                          )}
                        </div>
                        <div className={styles.tail}> {tail} </div>
                      </div>
                    );
                  })}
                </div>
              </Tooltip>
            )}
          </Overlay>
        </div>
      </div>
    </div>
  );
};

AircraftCluster.propTypes = {
  aircraftList: PropTypes.arrayOf(PropTypes.instanceOf(Aircraft)),
  index: PropTypes.number,
  onRowClick: PropTypes.func,
  pointCount: PropTypes.number,
  showTooltip: PropTypes.bool,
  setTooltipIndex: PropTypes.func,
};

export default AircraftCluster;
