import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  LRU_TYPE_ITU_10,
  LRU_TYPE_ITU_13,
  LRU_TYPE_ITU_18,
  LRU_TYPE_OVD,
} from 'constants';
import { Aircraft } from 'models';

import { Row, Col } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import LOPA from 'components/LOPA';

import styles from '../styles.module.css';

const ovdColumns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-Lru',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-Pos',
  },
  {
    name: 'HW part',
    key: 'hw_part',
    size: 'small',
    showOutliers: true,
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-HwPart',
  },
  {
    name: 'HW serial',
    key: 'hw_serial',
    size: 'small',
    showOutliers: true,
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-HwSerial',
  },
  {
    name: 'FW version',
    key: 'fw_version',
    showOutliers: true,
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-FwVersion',
  },
];

const ituColumns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-Lru',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-Pos',
  },
  {
    name: 'ITU ROM',
    key: 'itu_rom',
    size: 'xsmall',
    showOutliers: true,
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-ItuRom',
  },
  {
    name: 'ITU ISD',
    key: 'itu_isd',
    size: 'xsmall',
    showOutliers: true,
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-ItuIsd',
  },
  {
    name: 'ITU FSS',
    key: 'itu_fss',
    size: 'xsmall',
    showOutliers: true,
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-ItuFss',
  },
  {
    name: 'Map SW part',
    key: 'map_sw_part',
    size: 'xsmall',
    showOutliers: true,
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-MapSwPart',
  },
  {
    name: 'Map assets SW part',
    key: 'map_assets_sw_part',
    size: 'small',
    showOutliers: true,
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-MapAssetsSwPart',
  },
  {
    name: 'MCU',
    key: 'mcu',
    size: 'xsmall',
    dataCyClassName: 'Ship-PublicDisplaysTableColumn-Mcu',
  },
];

const PublicDisplays = (props) => {
  const { aircraft, onRowClick, selectedLru, push } = props;
  const { lopa: aircraftLopa } = aircraft;
  const { public_displays: publicDisplays } = aircraftLopa;

  const [activeButtonId, setActiveButtonId] = useState(null);

  const localOnRowClick = (lruId, buttonId = null) => {
    setActiveButtonId(buttonId);
    onRowClick(lruId);
  };

  const mcuButtonId = 'mcu';

  const lopaData = {};
  const pdTableDataMap = {};
  publicDisplays.forEach((pd) => {
    const { id, lru_type: lruType } = pd;
    const pdTypeName = lruType.value;
    const lru = aircraft.getLRUByTypePosition(pdTypeName, id);

    if (!pdTableDataMap[pdTypeName]) {
      pdTableDataMap[pdTypeName] = [];
    }

    const isITUlruType =
      lruType.id === LRU_TYPE_ITU_10.id ||
      lruType.id === LRU_TYPE_ITU_13.id ||
      lruType.id === LRU_TYPE_ITU_18.id;

    if (isITUlruType) {
      const { power_supply: lopaMCUID, power_supply_channel: lopaMCUChannel } =
        pd;
      const selected = selectedLru === id;

      let itu_isd;
      let itu_fss;
      let itu_rom;
      let map_assets_sw_part;
      let map_sw_part;

      if (lru?.software_version) {
        const { FSS, ISD, MAP, MASA, ROM } = lru.software_version;
        itu_fss = FSS;
        itu_isd = ISD;
        itu_rom = ROM;
        map_assets_sw_part = MASA;
        map_sw_part = MAP;
      }

      const mcuButton = (
        <div
          className={classnames(
            'skypulsetable_toggle_button',
            selected && activeButtonId === mcuButtonId ? 'active' : null,
          )}
          data-clickable-id={mcuButtonId}
        >
          {`MCU ${lopaMCUID}-J${lopaMCUChannel}`}
        </div>
      );

      pdTableDataMap[pdTypeName].push({
        itu_fss,
        itu_isd,
        itu_rom,
        lru: lruType.value,
        map_assets_sw_part,
        map_sw_part,
        mcu: mcuButton,
        pos: id,
        id: id,
      });
    } else {
      let fw_version;
      let hw_part;
      let hw_serial;
      if (lru?.software_version) {
        const {
          software_version: { CFW, FUB, SWB },
        } = lru;
        fw_version = SWB;
        hw_part = FUB;
        hw_serial = CFW;
      }
      pdTableDataMap[pdTypeName].push({
        fw_version,
        id: id,
        lru: pdTypeName,
        pos: id,
        hw_part,
        hw_serial,
      });
    }

    const deviceType = aircraftLopa.getDeviceTypeByLRUTypePosition(
      lruType.id,
      id,
    );
    // Lopa Data
    if (!lopaData[deviceType]) {
      lopaData[deviceType] = {};
    }
    lopaData[deviceType][id] = {
      active: true,
      selected: selectedLru === id,
    };
  });

  const tableMaxHeight = `calc(100%/${Object.keys(pdTableDataMap)?.length})`;
  return (
    <Row className={styles.root} noGutters>
      <Col className="h-100">
        <LOPA mode="config" data={lopaData} aircraft={aircraft} push={push} />
        <Row className={styles.tableContainer} noGutters>
          <Col>
            {Object.keys(pdTableDataMap).map((key, index) => {
              const tData = pdTableDataMap[key];
              const isITUlruType = tData[0].lru !== LRU_TYPE_OVD.value;
              return (
                <Row
                  key={index}
                  className={styles.multiTable}
                  style={{ maxHeight: tableMaxHeight }}
                  noGutters
                >
                  <Col className="h-100">
                    <SkyPulseTable
                      title={key}
                      columns={isITUlruType ? ituColumns : ovdColumns}
                      rows={tData}
                      onRowClick={localOnRowClick}
                      selectedId={selectedLru}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

PublicDisplays.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  onRowClick: PropTypes.func.isRequired,
  selectedLru: PropTypes.string,
};

export default PublicDisplays;
