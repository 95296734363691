import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BootstrapDatePicker from './ReactBootstrapDatePicker';
import Calendar from 'assets/icons/Calendar.svg';
import { FormGroup, Form } from 'react-bootstrap';
import styles from './styles.module.css';

const DatePicker = (props) => {
  const pickerRef = useRef(null);

  const {
    containerClassName,
    disabled,
    error,
    idPrefix,
    label,
    labelClassName,
    value,
    minDate,
    maxDate,
    minPreviousDays,
    maxFutureDays,
    minPreviousMonths,
    maxFutureMonths,
    minPreviousYears,
    maxFutureYears,
    onChange,
    ...passProps
  } = props;

  const handleChange = (value) => {
    // convert to utc datestamp
    const date = !value ? 0 : Date.parse(value.split('T')[0]);

    if (onChange) {
      onChange(date);
    }
  };

  const handleIconClick = () => {
    pickerRef.current.refs.input.focus();
  };

  let minDt = minDate;
  let maxDt = maxDate;

  if (minPreviousDays && !minDate) {
    minDt = new Date();
    minDt.setDate(minDt.getDate() - minPreviousDays);
  }

  if (minPreviousMonths && !minDate) {
    minDt = new Date();
    minDt.setMonth(minDt.getMonth() - minPreviousMonths);
  }

  if (minPreviousYears && !minDate) {
    minDt = new Date();
    minDt.setFullYear(minDt.getFullYear() - minPreviousYears);
  }

  if (maxFutureDays && !maxDate) {
    maxDt = new Date();
    maxDt.setDate(maxDt.getDate() + maxFutureDays);
  }

  if (maxFutureMonths && !maxDate) {
    maxDt = new Date();
    maxDt.setMonth(maxDt.getMonth() + maxFutureMonths);
  }

  if (maxFutureYears && !maxDate) {
    maxDt = new Date();
    maxDt.setFullYear(maxDt.getFullYear() + maxFutureYears);
  }

  return (
    <FormGroup className={classnames(styles.root, containerClassName)}>
      {!props.label ? null : (
        <Form.Label
          className={`label ${styles.label} ${labelClassName} ${error ? 'labelError' : null}`}
        >
          {label}
        </Form.Label>
      )}
      <BootstrapDatePicker
        {...passProps}
        idPrefix={`${idPrefix}DatePicker`}
        onChange={handleChange}
        ref={pickerRef}
        value={!value ? null : new Date(value).toISOString()}
        disabled={disabled}
        error={error}
        minDate={!minDt ? null : new Date(minDt).toISOString()}
        maxDate={!maxDt ? null : new Date(maxDt).toISOString()}
      />
      <img
        src={Calendar}
        alt=""
        className={styles.icon}
        onClick={handleIconClick}
      />
    </FormGroup>
  );
};

DatePicker.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.any,
  idPrefix: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.string,
  ]),
  minPreviousDays: PropTypes.number,
  maxFutureDays: PropTypes.number,
  minPreviousMonths: PropTypes.number,
  maxFutureMonths: PropTypes.number,
  minPreviousYears: PropTypes.number,
  maxFutureYears: PropTypes.number,
};

export default DatePicker;
