import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from 'components/LoadingSpinner';
import { Button, Col, Row } from 'react-bootstrap';
import Overlay from 'containers/Overlay';

import styles from './styles.module.css';

const ReportSummaryOverlay = (props) => {
  const { title, data, isOpen, onCancel, onDone, loading, loadingMessage } =
    props;

  const Loading = () => {
    return (
      <div
        data-cy-id="Report-CreationMessage-Modal"
        className={styles.loadingContainer}
      >
        <Row className={styles.loadingMsg}>
          {loadingMessage || 'Please wait while the report is created.'}
        </Row>
        <Row className={styles.spinner}>
          <LoadingSpinner />
        </Row>
      </div>
    );
  };

  const Footer = () => {
    return (
      <Row className={styles.footer} noGutters>
        <Col lg="auto">
          <Button
            className="button_reg"
            data-cy-id={`Report-${title}-Overlay-ShowAllButton`}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Col>
        <Col lg="auto">
          <Button
            className="button_call"
            data-cy-id={`Report-${title}-Overlay-DoneButton`}
            onClick={onDone}
          >
            Create report
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <Overlay
      data-cy-id={`Report-${title}-Overlay`}
      title={title}
      isOpen={isOpen}
      footer={!loading ? <Footer /> : null}
      contentClassName={styles.content}
      showClose={false}
      onCancel={() => {
        onCancel();
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <Row className={styles.root} noGutters>
          <Col lg="auto">
            <Row>
              <Col className={styles.header}>Create the following {title}:</Col>
            </Row>
            <Row>
              <Col className={styles.data}>{data}</Col>
            </Row>
          </Col>
        </Row>
      )}
    </Overlay>
  );
};

ReportSummaryOverlay.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
};

export default ReportSummaryOverlay;
