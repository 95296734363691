/* eslint-disable no-use-before-define */

import { DFPSingleton } from '@dfp/common';

import { aircraftGETById, aircraftGETByTail, aircraftGETAll } from 'libs/api';
import { FlightFactory } from 'classes/FlightFactory';
import { Aircraft } from 'models';
import store from 'store';

export class AircraftFactory extends DFPSingleton {
  static getAircraft(aircraftData) {
    return new Aircraft(aircraftData);
  }

  static getAircraftById(aid, dt) {
    return aircraftGETById(aid, dt).then((response) => {
      const {
        data: { error, result },
      } = response;
      if (error) {
        console.warn(`Error retrieving aircraft: ${error}`);
      }
      if (result !== 'OK') {
        return AircraftFactory.getAircraft(result);
      }
    });
  }

  static async getAircraftByTail(tail, dt) {
    // get aircraft from backend
    let {
      data: { error, result },
    } = await aircraftGETByTail(tail, dt);
    if (error) {
      console.warn(`Error retrieving aircraft: ${error}`);
    }
    if (result !== 'OK') {
      return AircraftFactory.getAircraft(result);
    }
  }

  static async getAllAircraft(dt) {
    // get aircraft from backend
    let {
      data: { error, result: aircrafts },
    } = await aircraftGETAll(dt);
    if (error) {
      console.warn(`Error retrieving aircraft: ${error}`);
    }

    return aircrafts.map((aircraftData) =>
      AircraftFactory.getAircraft(aircraftData),
    );
  }

  static getSummaryAircraftById(aid) {
    const { aircraftList } = store.getState();
    if (aircraftList && Array.isArray(aircraftList)) {
      return aircraftList.find((ac) => ac.id === aid);
    }
  }
}

Aircraft.prototype.getCurrentFlightSlug = Aircraft.prototype.getCurrentFlight;

function getCurrentFlight() {
  const flightSlug = this.getCurrentFlightSlug();
  if (!flightSlug) return;
  const { flightList } = store.getState();
  return flightList.find((ft) => flightSlug && ft.id === flightSlug.id);
}
Aircraft.prototype.getCurrentFlight = getCurrentFlight;

async function getFullCurrentFlight() {
  const flightSlug = this.getCurrentFlightSlug();
  if (!flightSlug) return;
  return await FlightFactory.getFlightById(flightSlug.id);
}
Aircraft.prototype.getFullCurrentFlight = getFullCurrentFlight;
