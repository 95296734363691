import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import LoadingSpinner from 'components/LoadingSpinner';
import { UserFactory } from 'classes';

import jwt_decode from 'jwt-decode';

import { initializeApp, unititializeApp } from 'actions';
import { DEV_USER, PATHS, ROLES, ADMIN_PATHS, ROLE_MARKETING } from 'constants';
import { withAuth, Log } from 'libs/oidc';
import store, { history } from 'store';
import IfCan from 'containers/IfCan';

import Dashboard from 'view/pages/default/Dashboard';
import Flight from 'view/pages/default/Flight';
import LRUFaults from 'view/pages/default/LRUFaults';
import Ship from 'view/pages/default/Ship';
import Reports from 'view/pages/default/Reports';
import ConfigurationHistory from 'view/pages/default/ConfigurationHistory';
import Unauthorized from 'view/pages/default/Unauthorized';
import Maintenance from 'view/pages/default/Maintenance';

const { configuration } = store.getState();
let startedInitialization = false;

const getUser = (user) => {
  return UserFactory.getUserById()
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      const {
        response: { status },
      } = error;
      if (status === 404) {
        user.id = user.ppr;
        user.first_name = user.given_name;
        user.last_name = user.family_name;
        const newUser = await UserFactory.getUser(user);
        return newUser.save();
      }
    });
};

class App extends Component {
  async componentDidMount() {
    Log.logger = console;
    Log.level = Log.DEBUG;
    const { rConfiguration } = this.props;

    const globalAuthEnabled = rConfiguration.ff_global_auth;
    let userData;

    // Fetching User from the backend;
    if (globalAuthEnabled) {
      const { isLoading } = this.props;
      let { userData: user } = this.props;
      if (isLoading || !user || user.expired) {
        return <LoadingSpinner display="ellipsis" />;
      }

      const accessData = jwt_decode(user.access_token);
      if (accessData.roles) {
        user.roles = ['Admin', 'Maintenance', 'Marketing', 'User'].reduce(
          (acc, internalRole) => {
            if (
              (Array.isArray(accessData.roles) &&
                accessData.roles.some((accessRole) =>
                  accessRole.includes(internalRole),
                )) ||
              (typeof accessData.roles === 'string' &&
                accessData.roles.includes(internalRole))
            ) {
              if (internalRole === 'Admin') {
                // @todo rename this internal role and update all referencing code
                acc.push(ROLES.getEnumerationItem('Administrator').id);
              } else {
                acc.push(ROLES.getEnumerationItem(internalRole).id);
              }
            }
            return acc;
          },
          [],
        );
      }

      userData = await getUser(user);
    } else {
      userData = await getUser(DEV_USER);
    }

    // Check for unthorized users
    if (userData.roles.length < 1) {
      return <Unauthorized />;
    }

    // init app
    if (!startedInitialization) {
      startedInitialization = true;
      store.dispatch(initializeApp(userData));
    }
  }

  render() {
    const { rConfiguration, rInitialized } = this.props;

    const lrusAndFaultsEnabled = rConfiguration.ff_lrus_and_faults;
    const rssiHeatmapEnabled = rConfiguration.ff_rssi_heatmap;

    // push user to admin app for admin routes
    const route = sessionStorage.getItem('route');
    if (Object.values(ADMIN_PATHS).indexOf(route) !== -1) {
      window.location = ADMIN_PATHS.home;
      return <></>;
    }

    if (!rInitialized) {
      return <LoadingSpinner />;
    }

    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Redirect
            exact
            from={PATHS.home}
            to={{
              pathname: PATHS.dashboard,
              search: window.location.search, // preserve params
            }}
          />
          <Route exact path={PATHS.dashboard} component={Dashboard} />
          <Route exact path={PATHS.flights} component={Flight} />
          <Route exact path={PATHS.flightsUsage} component={Flight} />
          <IfCan forbiddenRoles={[ROLE_MARKETING.value.name]}>
            <Route
              exact
              path={`${PATHS.ship}/:page?/:lru_pos?`}
              component={Ship}
            />
            <Route exact path={PATHS.flightsOverview} component={Flight} />
            {rssiHeatmapEnabled && (
              <Route exact path={PATHS.flightsHeatmap} component={Flight} />
            )}
            <Route
              exact
              path={`${PATHS.configurationHistory}/:id`}
              component={ConfigurationHistory}
            />
            {lrusAndFaultsEnabled && (
              <Route
                exact
                path={`${PATHS.lrus}/:page?`}
                component={LRUFaults}
              />
            )}
            <Route exact path={PATHS.maintenance} component={Maintenance} />
            <Route exact path={PATHS.reports} component={Reports} />
            <Route
              exact
              path={`${PATHS.reportShip}/:id?`}
              component={Reports}
            />
            <Route
              exact
              path={`${PATHS.reportFlight}/:id?`}
              component={Reports}
            />
            <Route
              exact
              path={`${PATHS.reportLruFaults}/:id?`}
              component={Reports}
            />
            <Route
              exact
              path={`${PATHS.reportLruDetail}/:id?`}
              component={Reports}
            />
            <Route
              exact
              path={`${PATHS.reportUsage}/:id?`}
              component={Reports}
            />
            <Route exact path={PATHS.reportAvailability} component={Reports} />
            <Route
              exact
              path={PATHS.reportConfigurableFeatures}
              component={Reports}
            />
          </IfCan>
          <Redirect to={PATHS.home} />
        </Switch>
      </ConnectedRouter>
    );
  }

  componentWillUnmount() {
    store.dispatch(unititializeApp());
  }
}

const state = (state) => {
  return {
    rConfiguration: state.configuration,
    rInitialized: state.initialized,
  };
};

const actions = {};

export default connect(
  state,
  actions,
)(configuration.ff_global_auth ? withAuth(App) : App);
