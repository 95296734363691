import React from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import DebugPanel from './DebugPanel';

import styles from './styles.module.css';

const StandardLayout = (props) => {
  const { children, debug, user, rConfiguration } = props;
  const inMaintenanceMode = rConfiguration.maintenance_mode;

  return (
    <Container className={styles.root} fluid>
      <Row className={styles.headerRow} noGutters>
        <Col>
          <Header />
        </Col>
      </Row>
      <Row
        className={
          inMaintenanceMode
            ? styles.bodyWithHeaderFooter
            : styles.bodyWithHeader
        }
        noGutters
      >
        <Col className={styles.body}>{children}</Col>

        {!debug ? null : (
          <DebugPanel configuration={rConfiguration} user={user} />
        )}
      </Row>
      {!inMaintenanceMode ? null : (
        <Footer message="Maintenance mode is enabled" />
      )}
    </Container>
  );
};

const state = (state) => {
  return {
    rConfiguration: state.configuration,
    debug: state.debug,
    user: state.user,
  };
};

const actions = {};

export default connect(state, actions)(StandardLayout);
