import React, { useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  AIRCRAFT_TYPE_A321NEO,
  AIRCRAFT_TYPE_A330941,
  AIRCRAFT_TYPE_B767400,
  LOPA_BODY_TYPE_NARROW,
  LOPA_BODY_TYPE_WIDE,
} from 'constants';

export function getEnumerationValues(enums, ids) {
  const output = Array.isArray(ids)
    ? ids.map((id) => enums[id].name)
    : enums[ids].name;
  return output;
}

export function renderEnumerationValues(enums, ids) {
  if (!ids) {
    return '--';
  }
  const output = Array.isArray(ids)
    ? ids.map((id) => enums[id].name).join(', ')
    : enums[ids].name;
  return output;
}

export function renderDate(utc) {
  if (utc) {
    const date = new Date(utc);
    return date.toDateString().split(' ').slice(1).join(' ');
  }
}

export function renderDateWithComma(utc) {
  const date = new Date(utc);
  const dateString = date.toDateString().split(' ').slice(1);
  dateString[1] = dateString[1] + ',';
  return dateString.join(' ');
}

export function renderTime(min) {
  let h = Math.floor(min / 60);
  let m = min % 60;
  m = m < 10 ? '0' + m : m;
  return `${h}:${m}`;
}

export function renderLabelText(label, text, classname = '', colLenght = 3) {
  return (
    <Row className={classname}>
      <Col md={colLenght} style={{ fontWeight: 700 }}>
        {' '}
        {label}{' '}
      </Col>
      <Col> {text ? text : 'N/A'} </Col>
    </Row>
  );
}

export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const getLopaBodyType = (aircraftType) => {
  switch (aircraftType) {
    case AIRCRAFT_TYPE_A330941.value.name:
    case AIRCRAFT_TYPE_B767400.value.name:
      return LOPA_BODY_TYPE_WIDE;
    case AIRCRAFT_TYPE_A321NEO.value.name:
    default:
      return LOPA_BODY_TYPE_NARROW;
  }
};

export const useLogPropChanges = (props) => {
  const prev = useRef({});

  useEffect(() => {
    // check each prop to see if it has changed
    const changed = {};
    const prevProps = Object.assign({}, prev.current);
    Object.entries(props).forEach(([key, prop]) => {
      if (prev.current[key] !== prop) {
        changed[key] = prop;
      }
    });

    if (Object.keys(changed).length > 0) {
      console.group('%c Props That Changed ', 'background: #FF0000;');
      console.log(prevProps);
      console.log(changed);
      console.groupEnd();
    }

    prev.current = props;
  }, [props]);
};
