import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { activeAircraft, aircraftFilters, aircraftList } from './aircraft';
import { currentTime, notificationSeenTime } from './time';
import { debug } from './debug';
import { activeFlight, flightList } from './flight';
import { initialized } from './initialized';
import { user } from './user';
import { fleetHealth } from './fleet';
import { configuration } from './configuration';
import { top5LRUTypeCollection, top5LRUDetail } from './kiosk';

const combinedReducers = (history) => {
  return combineReducers({
    activeAircraft,
    activeFlight,
    aircraftFilters,
    aircraftList,
    configuration,
    currentTime,
    debug,
    fleetHealth,
    flightList,
    router: connectRouter(history),
    initialized,
    user,
    top5LRUTypeCollection,
    top5LRUDetail,
    notificationSeenTime,
  });
};

export default combinedReducers;
