import React, { useState } from 'react';

import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  SELECT_OPTION_ALL,
  PATHS,
} from 'constants';

import { Row, Col, Button } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const idPrefix = 'LRUFaultFeatures';

const Features = (props) => {
  const { aircraftList, onFleetRowClick, push } = props;
  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);

  const columnDefinitions = [
    {
      name: 'Tail',
      key: 'tail',
      size: 'xsmall',
      sortable: true,
      dataCyClassName: 'LRUFaults-ShipConfigurableFeaturesTableColumn-Tail',
    },
    {
      name: 'Fleet',
      key: 'fleet',
      size: 'small',
      dataCyClassName: 'LRUFaults-ShipConfigurableFeaturesTableColumn-Fleet',
    },
    {
      name: 'Bluetooth zones enabled',
      key: 'bluetooth_zones',
      size: 'medium',
      sortable: true,
      showOutliers: fleetOption !== SELECT_OPTION_ALL.value,
      dataCyClassName:
        'LRUFaults-ShipConfigurableFeaturesTableColumn-BluetoothZonesEnabled',
    },
    {
      name: 'USB Disabled',
      key: 'usb_disabled',
      sortable: true,
      dataCyClassName:
        'LRUFaults-ShipConfigurableFeaturesTableColumn-UsbDisabled',
    },
  ];

  const onSelectChange = ({ target: { value } }) => {
    setFleetOption(value);
  };

  const data = aircraftList
    ? aircraftList
        .filter(
          (ac) =>
            fleetOption === SELECT_OPTION_ALL.value ||
            ac.aircraft_type.id === fleetOption,
        )
        .map((ac) => {
          const configValues = {};
          ac.configuration.forEach((config) => {
            if (config?.configuration_type?.value?.ac_code) {
              configValues[config.configuration_type.value.ac_code] =
                ac.getLatestConfigValueString(config.configuration_type);
            }
          });
          return {
            id: ac.id,
            tail: ac.tail,
            fleet: ac.aircraft_type.value.name,
            bluetooth_zones: configValues?.BTZ,
            usb_disabled: configValues?.USB,
          };
        })
    : [];

  return (
    <Row className={styles.root} noGutters>
      <Col>
        <Row className={styles.title} noGutters>
          <Col data-cy-id="LRUFaults-Header" className="title_1">
            Fleet configurable features
          </Col>
          <Col md="auto">
            <Button
              className="button_reg"
              data-cy-id="LRU-LRUFaultsReportButton"
              onClick={() => push(PATHS.reportLruFaults)}
            >
              LRUs & Faults reports
            </Button>
          </Col>
        </Row>
        <Row className={styles.fleetSelectionContainer} noGutters>
          <Col md="auto">
            <Select
              idPrefix={idPrefix}
              label="Fleet: "
              onChange={onSelectChange}
              options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
              value={fleetOption}
              data-cy-id="LRUFaults-FleetSelect"
            />
          </Col>
        </Row>
        <Row className={styles.tableContainer} noGutters>
          <Col className="h-100">
            <SkyPulseTable
              columns={columnDefinitions}
              rows={data}
              title="Ship - Configurable features"
              defaultSort="tail"
              defaultSortState="ascending"
              onRowClick={(id) => {
                onFleetRowClick(id);
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Features;
