import React from 'react';
import classNames from 'classnames';

import { NOT_AVAILABLE } from 'constants';

import { Row, Col } from 'react-bootstrap';

import styles from './styles.module.css';

const columns = [
  {
    name: 'Active content set (Server)',
    key: 'active_contentset',
  },
  {
    name: 'Staged content set (SSDCL)',
    //TODO: Need correct value from ICD
    key: 'staged_contentset',
  },
  {
    name: 'Content set loading progress (SSDCL to server)',
    //TODO: Need correct value from ICD
    key: 'contentset_loading_progress',
  },
  {
    name: 'Content cache progress (Server to ITUs)',
    key: 'contentset_cache_progress',
  },
  {
    name: 'Active safety video',
    key: 'active_safety_video',
  },
  {
    name: 'Staged safety video',
    key: 'staged_safety_video',
  },
  {
    name: 'Safety video cache progress (Server to ITUs)',
    key: 'safety_video_cache_progress',
  },
];

const ShipContent = (props) => {
  const { aircraft } = props;
  const activeContentset = aircraft.active_contentset || {};
  const stagedContentset = aircraft.staged_contentset || {};
  const activeContentsetHistoryItem = activeContentset.getLatestHistory
    ? activeContentset.getLatestHistory() || {}
    : {};
  const stagedContentsetHistoryItem = stagedContentset.getLatestHistory
    ? stagedContentset.getLatestHistory() || {}
    : {};

  const {
    global_id: activeContentsetId,
    safety_video_id: activeSafetyVideoId,
  } = activeContentset;

  const {
    cache_progress: activeContentsetCacheProgress,
    load_progress: activeContentsetLoadProgress,
    safety_video_cache_progress: activeSafetyVideoCacheProgress,
  } = activeContentsetHistoryItem;

  const {
    global_id: stagedContentsetId,
    safety_video_id: stagedSafetyVideoId,
  } = stagedContentset;

  const {
    cache_progress: stagedContentsetCacheProgress,
    load_progress: stagedContentsetLoadProgress,
    safety_video_cache_progress: stagedSafetyVideoCacheProgress,
  } = stagedContentsetHistoryItem;

  const contentsetLoadingProgress = stagedContentsetLoadProgress
    ? `${stagedContentsetLoadProgress}%`
    : activeContentsetLoadProgress
      ? `${activeContentsetLoadProgress}%`
      : NOT_AVAILABLE;

  const contentsetCacheProgress = stagedContentsetCacheProgress
    ? `${stagedContentsetCacheProgress}%`
    : activeContentsetCacheProgress
      ? `${activeContentsetCacheProgress}%`
      : NOT_AVAILABLE;

  const safetyVideoCacheProgress = stagedSafetyVideoCacheProgress
    ? `${stagedSafetyVideoCacheProgress}%`
    : activeSafetyVideoCacheProgress
      ? `${activeSafetyVideoCacheProgress}%`
      : NOT_AVAILABLE;

  const data = {
    active_contentset: activeContentsetId || NOT_AVAILABLE,
    staged_contentset: stagedContentsetId || NOT_AVAILABLE,
    contentset_loading_progress: contentsetLoadingProgress,
    contentset_cache_progress: contentsetCacheProgress,
    active_safety_video: activeSafetyVideoId || NOT_AVAILABLE,
    staged_safety_video: stagedSafetyVideoId || NOT_AVAILABLE,
    safety_video_cache_progress: safetyVideoCacheProgress,
  };

  return (
    <Row className={styles.root} data-cy-id="Ship-ContentPanel" noGutters>
      <Col>
        <Row
          data-cy-id="Ship-ContentTable"
          className={styles.tableRow}
          noGutters
        >
          <Col>
            <Row
              data-cy-id="Ship-ContentTable-Title"
              className={styles.tableHeaderRow}
            >
              <Col md="auto" className="title_4">
                Status
              </Col>
            </Row>
            <Row
              data-cy-id="Ship-ContentTable-Body"
              className={styles.tableBodyRow}
              noGutters
            >
              <Col>
                {columns.map((col, index) => {
                  const { key, name } = col;
                  return (
                    <Row
                      data-cy-id="Ship-ContentTable-Row"
                      key={index}
                      className={styles.recordRow}
                      noGutters
                    >
                      <Col
                        data-cy-id="Ship-ContentTable-Column"
                        md={8}
                        className={classNames(styles.column, styles.label)}
                      >
                        <Row noGutters>
                          <Col>{name}</Col>
                        </Row>
                      </Col>
                      <Col
                        data-cy-id="Ship-ContentTable-Column"
                        className={styles.column}
                      >
                        <Row noGutters>
                          <Col>{data[key]}</Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ShipContent;
