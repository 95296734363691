import { DFPSingleton } from '@dfp/common';

import { lrusGETAllByType, lruGETById } from 'libs/api';
import { LRU } from 'models';

export class LRUFactory extends DFPSingleton {
  static getLru(LRUData) {
    return new LRU(LRUData);
  }

  static getLRUsByType(type, opts) {
    return lrusGETAllByType(type, opts).then((response) => {
      const {
        data: { result },
      } = response;
      return result.map((lru) => LRUFactory.getLru(lru));
    });
  }

  static async getLruById(id, dt) {
    return lruGETById(id, dt).then((response) => {
      let {
        data: { result },
      } = response;
      return LRUFactory.getLru(result);
    });
  }
}
