import React from 'react';
import PropTypes from 'prop-types';
import { Aircraft } from 'models';

import { Row, Col } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import LOPA from 'components/LOPA';

import styles from '../styles.module.css';

const columns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
    dataCyClassName: 'Ship-PcusTableColumn-Lru',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
    dataCyClassName: 'Ship-PcusTableColumn-Pos',
  },
  {
    name: 'FW version',
    key: 'fw_version',
    size: 'large',
    showOutliers: true,
    dataCyClassName: 'Ship-PcusTableColumn-FwVersion',
  },
];

const PCUs = (props) => {
  const { aircraft, onRowClick, selectedLru, push } = props;
  const { lopa: aircraftLopa } = aircraft;
  const { pcus } = aircraftLopa;

  const lopaData = {
    passenger_displays: {},
  };
  const pcuTableDataMap = {};
  pcus.forEach(({ id, lru_type: lruType }) => {
    const pcuTypeName = lruType.value;

    if (!pcuTableDataMap[pcuTypeName]) {
      pcuTableDataMap[pcuTypeName] = [];
    }

    pcuTableDataMap[pcuTypeName].push({
      id: id,
      lru: pcuTypeName,
      pos: id,
      fw_version: null,
    });

    // Lopa Data
    lopaData.passenger_displays[id] = {
      active: true,
      selected: selectedLru === id,
    };
  });

  const tableMaxHeight = `calc(100%/${Object.keys(pcuTableDataMap)?.length})`;
  return (
    <Row className={styles.root} noGutters>
      <Col className="h-100">
        <LOPA mode="config" data={lopaData} aircraft={aircraft} push={push} />
        <Row className={styles.tableContainer} noGutters>
          <Col className="h-100">
            {Object.keys(pcuTableDataMap).map((key, index) => {
              const tData = pcuTableDataMap[key];
              return (
                <Row
                  key={index}
                  className={styles.multiTable}
                  style={{ maxHeight: tableMaxHeight }}
                  noGutters
                >
                  <Col className="h-100">
                    <SkyPulseTable
                      title={key}
                      columns={columns}
                      rows={tData}
                      onRowClick={onRowClick}
                      selectedId={selectedLru}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

PCUs.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  onRowClick: PropTypes.func.isRequired,
  selectedLru: PropTypes.string,
};

export default PCUs;
