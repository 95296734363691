import { UserFactory } from 'classes';
import store from 'store';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';

export function getUser(user) {
  return async (dispatch) => {
    if (!user) {
      const { user: currentUser } = store.getState();
      let lastFetchDt;
      if (currentUser) {
        lastFetchDt = currentUser.modified + 1000;
      }
      user = await UserFactory.getUserById(lastFetchDt);
    }
    dispatch({
      type: GET_USER,
      user,
    });
  };
}

export function updateUser(user) {
  return async (dispatch) => {
    await user.save().then((savedUser) => {
      dispatch({
        type: UPDATE_USER,
        user: savedUser,
      });
    });
  };
}
