import React, { useState, useEffect, useRef } from 'react';
import { UserManager, Log } from 'oidc-client';
// auth context
export const AuthContext = React.createContext(undefined);

/**
 * Responsible for capturing oidc auth code in
 * the URI query string
 * @param {*} location the window location
 * @returns {boolean} returns true if code is a query parameter
 */
export const hasCodeInUrl = (location) => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get('code');
};

/**
 * Responsible for removing the querystring from
 * the URL without reloading the page
 */
export const removeQueryFromUrl = (toRemoveParams = []) => {
  let url;
  if (toRemoveParams.lengh > 0) {
    url = new URL(window.location);
    toRemoveParams.forEach((param) => url.searchParams.delete(param));
  } else {
    url = new URL(window.location.origin);
  }

  window.history.replaceState(null, null, url);
};

/**
 * Responsible for finding errors in the URI query
 * string
 * @param {*} location the window location
 * @returns {boolean} returns true if there is an error query parameter
 */
export const hasErrorInUrl = (location) => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get('error');
};

/**
 * Responsible for capturing the oidc configuration
 * @param {*} props the configuration props supplied to the AuthProvider
 * @returns {UserManager} the configured user manager
 */
export const initUserManager = (props) => {
  if (props.userManager) {
    return props.userManager;
  }
  const {
    authority,
    clientId,
    clientSecret,
    redirectUri,
    silentRedirectUri,
    postLogoutRedirectUri,
    responseType,
    scope,
    automaticSilentRenew,
    loadUserInfo,
    popupWindowFeatures,
    popupRedirectUri,
    popupWindowTarget,
  } = props;
  return new UserManager({
    authority,
    client_id: clientId,
    client_secret: clientSecret,
    redirect_uri: redirectUri,
    silent_redirect_uri: silentRedirectUri || redirectUri,
    post_logout_redirect_uri: postLogoutRedirectUri || redirectUri,
    response_type: responseType || 'code',
    scope: scope || 'openid',
    loadUserInfo: loadUserInfo !== undefined ? loadUserInfo : true,
    popupWindowFeatures: popupWindowFeatures,
    popup_redirect_uri: popupRedirectUri,
    popupWindowTarget: popupWindowTarget,
    automaticSilentRenew,
  });
};

/**
 * Responsible for providing oidc authentication
 * via higher order component
 * @param {Object} children
 * @param {boolean} autoSignIn
 * @param {function} onSignIn
 * @param {object} location
 * @param {*} props
 * @returns
 */
export const AuthProvider = ({
  children,
  autoSignIn = true,
  onSignIn,
  location = window.location,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [userManager] = useState(() => initUserManager(props));
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      // throw auth errors back to ping
      if (hasErrorInUrl(location)) {
        Log.debug('AuthContext.useEffect: error found in URL');
        await userManager.signinRedirect();
        return;
      }

      // capture authenticated users via the auth code
      if (hasCodeInUrl(location)) {
        Log.debug('AuthContext.useEffect: auth code found in URL');

        let user;
        if (window.top === window.self) {
          user = await userManager.signinCallback();
          removeQueryFromUrl(['code', 'state']);
        } else {
          user = await userManager.signinSilentCallback();
        }

        setUserData(user);
        setIsLoading(false);
        if (onSignIn) {
          onSignIn(user);
        }

        return;
      }

      // set the current user in state
      Log.debug('AuthContext.useEffect: get user');
      const user = await userManager.getUser();
      if (isMountedRef.current) {
        setUserData(user);
        setIsLoading(false);
      }

      // expired or unauthenticated users
      Log.debug('AuthContext.useEffect: user', user);
      if ((!user || user.expired) && autoSignIn) {
        Log.debug('AuthContext.useEffect: no user or user expired');
        await userManager.signinRedirect();
      } else if (isMountedRef.current) {
        setUserData(user);
        setIsLoading(false);
      }

      return;
    })();
  }, [location, userManager, autoSignIn, onSignIn]);

  useEffect(() => {
    const updateUserData = async () => {
      const user = await userManager.getUser();
      if (isMountedRef.current) {
        setUserData(user);
      }
    };

    userManager.events.addUserLoaded(updateUserData);
    userManager.events.addAccessTokenExpiring(() => {
      Log.debug('AuthContext.useEffect: access token expiring, signin silent');
      userManager.signinSilent();
    });
  }, [userManager]);

  return (
    <AuthContext.Provider
      value={{
        signIn: async (args) => {
          await userManager.signinRedirect(args);
        },
        signInSilent: async (args) => {
          await userManager.signinSilent(args);
        },
        userManager,
        userData,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
