import React, { useState, useEffect } from 'react';

import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  DEFAULT_OPTION,
  SELECT_OPTION_ALL,
} from 'constants';
import { LRUFactory, ReportFactory } from 'classes';

import { Row, Col, Button } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import ReportSummaryOverlay from 'components/overlays/ReportSummaryOverlay';

import styles from './styles.module.css';

const idPrefix = 'LRUDetailReport';
const LRUDetail = (props) => {
  const { aircraftList, LruId } = props;

  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);
  const [shipOption, setShipOption] = useState('');
  const [LRUOption, setLRUOption] = useState('');
  const [positionOption, setPositionOption] = useState('');
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [data, setData] = useState(null);
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);
  const [selectedAircraftId, setSelectedAircraftId] = useState('');

  useEffect(() => {
    const getData = async () => {
      const data = await LRUFactory.getLruById(LruId);
      setData(data);
    };
    if (LruId && !LRUOption) {
      getData();
      if (data) {
        const aircraft = aircraftList.find((ac) => ac.id === data.aircraft_id);
        setShipOption(aircraft ? aircraft.tail : '');
        setSelectedAircraftId(aircraft ? aircraft.id : '');
        setLRUOption(data ? data.lru_type?.value : '');
        setPositionOption(data ? data.position : '');
        setOverlayOpen(true);
      }
    }
  }, [LruId, LRUOption, aircraftList, data]);

  const ship = [DEFAULT_OPTION]
    .concat(
      !aircraftList
        ? []
        : (!fleetOption
            ? aircraftList
            : aircraftList.filter(
                (aircraft) => aircraft?.aircraft_type?.id === fleetOption,
              )
          ).map((aircraft) => {
            return {
              value: aircraft.id,
              name: aircraft.tail,
            };
          }),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const selectedAircraft = !selectedAircraftId
    ? null
    : aircraftList.find((a) => a.id === selectedAircraftId);

  const aircraftLopa = selectedAircraft ? selectedAircraft.lopa : null;

  const lopaMap = {};
  aircraftLopa &&
    Object.keys(aircraftLopa).forEach((type) => {
      if (type === 'id' || type === 'classes') {
        return;
      }
      Object.values(aircraftLopa[type]).forEach((value) => {
        if (!lopaMap[value.lru_type.value]) {
          lopaMap[value.lru_type.value] = [];
        }
        lopaMap[value.lru_type.value].push(value.id);
      });
    });

  const LRUs = [DEFAULT_OPTION].concat(
    Object.keys(lopaMap).map((type) => {
      return {
        value: type,
        name: type,
      };
    }),
  );

  const aircraftType = selectedAircraft?.aircraft_type;

  const position =
    aircraftType && LRUOption
      ? [DEFAULT_OPTION].concat(
          lopaMap[LRUOption].map((p) => {
            return {
              value: p,
              name: p,
            };
          }),
        )
      : [DEFAULT_OPTION];

  return (
    <>
      <Row className={styles.root} noGutters>
        <Col>
          <Row className={styles.title} noGutters>
            <Col data-cy-id={`${idPrefix}-ScreenHeader`} className="title_1">
              {' '}
              LRU detail report{' '}
            </Col>
            <Col md="auto" className={styles.note}>
              Note: Data is available for previous 30 days.
            </Col>
          </Row>
          <Row
            className={styles.filterRow}
            data-cy-id={`${idPrefix}-Filters`}
            noGutters
          >
            <Col>
              <Row>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-Fleet`}
                    data-cy-id={`${idPrefix}-FiltersPanel-FleetSelect`}
                    label="Fleet: "
                    options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
                    value={fleetOption}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setFleetOption(value);
                      setShipOption('');
                      setSelectedAircraftId('');
                      setLRUOption('');
                      setPositionOption('');
                    }}
                  />
                </Col>
                <Col md="auto">
                  <Select
                    idPrefix={`${idPrefix}-Ship`}
                    data-cy-id={`${idPrefix}-FiltersPanel-TailSelect`}
                    label="Tail: "
                    options={ship}
                    value={selectedAircraftId}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      const selectedAircraft = aircraftList.find(
                        (ac) => ac.id === value,
                      );
                      setShipOption(selectedAircraft.tail);
                      setSelectedAircraftId(value);
                      setLRUOption('');
                      setPositionOption('');
                    }}
                  />
                </Col>
                <Col md="auto">
                  <Select
                    idPrefix={`${idPrefix}-Departure-utc`}
                    data-cy-id={`${idPrefix}-FiltersPanel-LruSelect`}
                    label="LRU: "
                    options={LRUs}
                    value={LRUOption}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setLRUOption(value);
                      setPositionOption('');
                    }}
                  />
                </Col>
                <Col md="auto">
                  <Select
                    idPrefix={`${idPrefix}-Flight`}
                    data-cy-id={`${idPrefix}-FiltersPanel-PositionSelect`}
                    label="Position: "
                    options={position}
                    value={positionOption}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setPositionOption(value);
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col md="auto">
              <Button
                className="button_foc"
                data-cy-id={`${idPrefix}-CreateReportButton`}
                disabled={!positionOption}
                onClick={() => setOverlayOpen(true)}
              >
                Create report
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ReportSummaryOverlay
        title="LRU detail report"
        data={
          <>
            <Row>
              <Col> Fleet </Col>
              <Col>
                {' '}
                {fleetOption === ''
                  ? 'All'
                  : AIRCRAFT_TYPE_OPTIONS_W_ALL[fleetOption].name}{' '}
              </Col>
            </Row>
            <Row>
              <Col> Tail </Col>
              <Col> {shipOption} </Col>
            </Row>
            <Row>
              <Col> LRU </Col>
              <Col> {LRUOption === '' ? null : LRUOption} </Col>
            </Row>
            <Row>
              <Col> Position </Col>
              <Col> {positionOption} </Col>
            </Row>
          </>
        }
        isOpen={overlayOpen}
        loading={messageOverlayOpen}
        onCancel={() => setOverlayOpen(false)}
        onDone={async () => {
          setMessageOverlayOpen(true);
          await ReportFactory.getLruDetailReport(
            selectedAircraftId,
            LRUOption,
            positionOption,
          );
          setMessageOverlayOpen(false);
          setOverlayOpen(false);
        }}
      />
    </>
  );
};

export default LRUDetail;
