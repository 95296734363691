import React from 'react';
import PropTypes from 'prop-types';

import { Aircraft, LRU } from 'models';

import { Row, Col } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const PassengerDisplays = (props) => {
  const { lruData, aircraft } = props;

  let ITUclass;
  let mcuId;
  let mcuChannel;
  if (aircraft) {
    const aircraftLopa = aircraft.lopa;
    const { passenger_displays: passengerDisplays, classes } = aircraftLopa;
    const selectedITU = passengerDisplays.find(
      (pd) => pd.id === lruData.position,
    );
    mcuId = selectedITU.power_supply;
    mcuChannel = selectedITU.power_supply_channel;
    ITUclass = classes.find(({ id }) => id === selectedITU.class);
  }

  const overviewCol = [
    {
      name: 'Tail',
      key: 'tail',
      size: 'tiny',
      dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Tail',
    },
    {
      name: 'LRU',
      key: 'lru',
      dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Lru',
    },
    {
      name: 'Pos',
      key: 'pos',
      size: 'tiny',
      dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Pos',
    },
    {
      name: 'ITU ROM',
      key: 'itu_rom',
      size: 'xsmall',
      dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-ItuRom',
    },
    {
      name: 'ITU ISD',
      key: 'itu_isd',
      dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-ItuIsd',
    },
    {
      name: 'ITU FSS',
      key: 'itu_fss',
      dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-ItuFss',
    },
    {
      name: 'Map SW part',
      key: 'map_sw_part',
      dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-MapSwPart',
    },
    {
      name: 'Map assets SW part',
      key: 'map_assets_sw_part',
      className: styles.smallCol,
      dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-MapAssetsSwPart',
    },
    {
      name: 'Class',
      key: 'class',
      size: 'xsmall',
      dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Class',
    },
    {
      name: 'Audio zone',
      key: 'audio_zone',
      size: 'xsmall',
      dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-AudioZone',
    },
    {
      name: 'MCU',
      key: 'mcu',
    },
  ];

  const latestLRUHistory = lruData.getLatestHistory();
  let itu_isd;
  let itu_fss;
  let itu_rom;
  let map_assets_sw_part;
  let map_sw_part;
  if (latestLRUHistory?.software_version) {
    const { FSS, ISD, MAP, MASA, ROM } = latestLRUHistory.software_version;
    itu_fss = FSS;
    itu_isd = ISD;
    itu_rom = ROM;
    map_assets_sw_part = MASA;
    map_sw_part = MAP;
  }

  const overviewData = [
    {
      tail: aircraft?.tail,
      lru: lruData.lru_type.value,
      pos: lruData.position,
      itu_rom: itu_rom,
      itu_isd: itu_isd,
      itu_fss: itu_fss,
      map_sw_part: map_sw_part,
      map_assets_sw_part: map_assets_sw_part,
      class: ITUclass?.name,
      audio_zone: ITUclass?.audio_zone,
      mcu: `MCU ${mcuId}-J${mcuChannel}`,
    },
  ];

  //TODO
  // const faultData = [
  //   {
  //     fault: 'Resets',
  //     last_flight: 'N/A',
  //     avg: 'N/A',
  //   },
  // ];

  return (
    <Row
      data-cy-id="LruDetailsOverlay-overviewContainer"
      className={styles.overviewContainer}
      noGutters
    >
      <Col>
        <Row>
          <Col
            data-cy-id="LruDetailsOverlay-overviewTableTitle"
            className={styles.title}
          >
            Overview
          </Col>
        </Row>
        <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable columns={overviewCol} rows={overviewData} />
          </Col>
        </Row>
        {/* <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable
              columns={faultCol}
              rows={faultData}
            />
          </Col>
        </Row> */}
      </Col>
    </Row>
  );
};

PassengerDisplays.propTypes = {
  lruData: PropTypes.instanceOf(LRU).isRequired,
  aircraft: PropTypes.instanceOf(Aircraft),
};

export default PassengerDisplays;
