import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import IfCan from 'containers/IfCan';
import LeftNav from 'components/default/LeftNav';
import A220Logs from './A220Logs';

import styles from './styles.module.css';

const NavMap = {
  '': [
    {
      display: 'A220 Logs',
      id: 'a220Logs',
    },
  ],
};

const Maintenance = ({ rAircraftList }) => {
  const renderMenuItem = () => {
    return <A220Logs aircraftList={rAircraftList} />;
  };

  return (
    <IfCan allowedRoles={['Maintenance']} alternateContent={<></>}>
      <Row className={styles.root} data-cy-id="Maintenance" noGutters>
        <Col md="auto" className={styles.leftPanel}>
          <LeftNav
            activeMenuItem={'A220 Logs'}
            navMap={NavMap}
            primaryHeader="Maintenance"
            testIdPrefix="Maintenance"
          />
        </Col>
        <Col className={styles.container}>{renderMenuItem()}</Col>
      </Row>
    </IfCan>
  );
};

const state = (state) => {
  return {
    rAircraftList: state.aircraftList,
    user: state.user,
  };
};

const actions = {};

export default connect(state, actions)(Maintenance);
