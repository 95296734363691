import React from 'react';
import PropTypes from 'prop-types';

import { LRU_TYPE_ITU_10, LRU_TYPE_ITU_13, LRU_TYPE_ITU_18 } from 'constants';
import { Aircraft } from 'models';

import { Row, Col } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import LOPA from 'components/LOPA';

import styles from '../styles.module.css';

const ituColumns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
    dataCyClassName: 'Ship-CrewPanelsTableColumn-Lru',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
    dataCyClassName: 'Ship-CrewPanelsTableColumn-Pos',
  },
  {
    name: 'ITU ROM',
    key: 'itu_rom',
    size: 'xsmall',
    showOutliers: true,
    dataCyClassName: 'Ship-CrewPanelsTableColumn-ItuRom',
  },
  {
    name: 'ITU FSS',
    key: 'itu_fss',
    size: 'xsmall',
    showOutliers: true,
    dataCyClassName: 'Ship-CrewPanelsTableColumn-ItuFss',
  },
  {
    name: 'Map SW part',
    key: 'map_sw_part',
    size: 'xsmall',
    showOutliers: true,
    dataCyClassName: 'Ship-CrewPanelsTableColumn-MapSwPart',
  },
  {
    name: 'Map assets SW part',
    key: 'map_sw_assets_part',
    size: 'small',
    showOutliers: true,
    dataCyClassName: 'Ship-CrewPanelsTableColumn-MapSwAssetsPart',
  },
];

const fapColumns = [
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
    dataCyClassName: 'Ship-CrewPanelsTableColumn-Lru',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
    dataCyClassName: 'Ship-CrewPanelsTableColumn-Pos',
  },
];

const CrewPanels = (props) => {
  const { aircraft, onRowClick, selectedLru, push } = props;
  const { lopa: aircraftLopa } = aircraft;
  const { crew_panels: crewPanels } = aircraftLopa;

  const lopaData = {};
  const cmpTableDataMap = {};
  crewPanels.forEach((cmp) => {
    const { id, lru_type: lruType } = cmp;
    const cmpTypeName = lruType.value;
    const lru = aircraft.getLRUByTypePosition(cmpTypeName, id);

    if (!cmpTableDataMap[cmpTypeName]) {
      cmpTableDataMap[cmpTypeName] = [];
    }

    let itu_fss;
    let itu_rom;
    let map_sw_assets_part;
    let map_sw_part;

    if (lru?.software_version) {
      const { FSS, MAP, MASA, ROM } = lru.software_version;
      itu_fss = FSS;
      itu_rom = ROM;
      map_sw_assets_part = MASA;
      map_sw_part = MAP;
    }

    cmpTableDataMap[cmpTypeName].push({
      itu_fss,
      itu_rom,
      map_sw_part,
      map_sw_assets_part,
      lru: lruType.value,
      pos: id,
      id: id,
    });

    const deviceType = aircraftLopa.getDeviceTypeByLRUTypePosition(
      lruType.id,
      id,
    );
    // Lopa Data
    if (!lopaData[deviceType]) {
      lopaData[deviceType] = {};
    }
    lopaData[deviceType][id] = {
      active: true,
      selected: selectedLru === id,
    };
  });

  const tableMaxHeight = `calc(100%/${Object.keys(cmpTableDataMap)?.length})`;
  return (
    <Row className={styles.root} noGutters>
      <Col className="h-100">
        <LOPA mode="config" data={lopaData} aircraft={aircraft} push={push} />
        <Row className={styles.tableContainer} noGutters>
          <Col className="h-100 d-flex flex-column justify-content-evenly">
            {Object.keys(cmpTableDataMap).map((key, index) => {
              const tData = cmpTableDataMap[key];
              const isITUlruType =
                tData[index].lru === LRU_TYPE_ITU_10.value ||
                tData[index].lru === LRU_TYPE_ITU_13.value ||
                tData[index].lru === LRU_TYPE_ITU_18.value;
              return (
                <Row
                  key={index}
                  className={styles.multiTable}
                  style={{ maxHeight: tableMaxHeight }}
                  noGutters
                >
                  <Col className="h-100">
                    <SkyPulseTable
                      title={`CMP (${key})`}
                      columns={isITUlruType ? ituColumns : fapColumns}
                      rows={tData}
                      onRowClick={onRowClick}
                      selectedId={selectedLru}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CrewPanels.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default CrewPanels;
