/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSVLink } from 'react-csv';
import ButtonItem from 'components/inputs/ButtonItem';
import LoadingSpinner from 'components/LoadingSpinner';
import upFilledArrow from 'assets/icons/upFilledArrow.svg';
import downFilledArrow from 'assets/icons/downFilledArrow.svg';
import { NOT_APPLICABLE, NOT_AVAILABLE } from 'constants';

import { useIsElementVisible } from './paginated.js';

import { Row, Col } from 'react-bootstrap';

import styles from './styles.module.css';

function isObject(obj) {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
}

const SkyPulseTable = (props) => {
  const {
    allowExport,
    className,
    columns,
    defaultSort,
    defaultSortState,
    exportFileName,
    hasMoreData,
    headerRowClassname,
    isLoading,
    onRowClick,
    onScrollEnd,
    onScrollStart,
    onUpdateSortParameters,
    rows,
    selectedId,
    showHeaders,
    tableClassname,
    title,
    titleClassName,
  } = props;

  const [sortState, setSortState] = useState(
    Object.assign(
      {},
      {
        key: defaultSort,
        dir: defaultSort ? defaultSortState || 'ascending' : null,
      },
    ),
  );
  const [exportData, setExportData] = useState([]);

  // pagination states
  const lastElementRef = useRef(null);
  const isLastElementVisible = useIsElementVisible(lastElementRef.current);
  const firstElementRef = useRef(null);
  const isFirstElementVisible = useIsElementVisible(firstElementRef.current);

  useEffect(() => {
    if (isLastElementVisible && onScrollEnd) {
      onScrollEnd();
      window.scrollTo(0, document.documentElement.offsetHeight);
    }
  }, [isLastElementVisible, hasMoreData]);

  useEffect(() => {
    if (isFirstElementVisible && onScrollStart) {
      onScrollStart();
    }
  }, [isFirstElementVisible]);

  const setSort = (key) => {
    // set sort state for local sorting
    let newSortState = Object.assign({}, sortState);
    if (newSortState.key !== key) {
      newSortState.key = key;
      newSortState.dir = 'ascending';
    } else if (newSortState.dir === 'ascending') {
      newSortState.dir = 'descending';
    } else {
      newSortState.key = null;
      newSortState.dir = null;
    }

    // set sort state for API sorting
    if (hasMoreData) {
      onUpdateSortParameters({
        columnKey: newSortState.key,
        sortDirection: newSortState.dir,
      });
    }

    setSortState(newSortState);
  };

  const handleRowClick = (e) => {
    if (onRowClick) {
      const { target } = e;

      const findId = (target) => {
        if (target.id) {
          return target.id;
        }
        return target.parentNode ? findId(target.parentNode) : undefined;
      };
      const id = findId(target);

      // clickable elements within the column are designated with a click id
      // click ids can be assigned to any element you wnat to trigger a rowclick with a special value
      const clickId = target.dataset.clickableId;

      if (id) {
        onRowClick(id, clickId);
      }
    }
  };

  const getSizeClass = (size) => {
    switch (size) {
      case 'tiny':
        return styles.tinyColumn;
      case 'xsmall':
        return styles.xsmallColumn;
      case 'small':
        return styles.smallColumn;
      case 'medium':
        return styles.mediumColumn;
      case 'large':
        return styles.largeColumn;
      case 'xlarge':
        return styles.xlargeColumn;
      default:
        console.warn(
          `SkyPulseTable encountered an unrecognized column size definition: ${size}`,
        );
    }
  };

  const sortedRows = sortState.dir
    ? [...rows].sort((a, b) => {
        const { key, dir } = sortState;

        let aSort = a[key];
        if (aSort) {
          aSort = isObject(aSort) ? aSort.sort : aSort;
        }

        let bSort = b[key];
        if (bSort) {
          bSort = isObject(bSort) ? bSort.sort : bSort;
        }

        if (!aSort && !bSort) {
          return 0;
        }

        // if either sort value is numeric assume numeric comparison
        if (Number.isNumeric(aSort) || Number.isNumeric(bSort)) {
          if (!Number.isNumeric(aSort)) aSort = 0;
          if (!Number.isNumeric(bSort)) bSort = 0;
          return dir === 'ascending' ? aSort - bSort : bSort - aSort;
        }

        return dir === 'ascending'
          ? (aSort || '').localeCompare(bSort || '', 'en', { numeric: true })
          : (bSort || '').localeCompare(aSort || '', 'en', { numeric: true });
      })
    : rows;

  const outlierMap = {};
  columns.forEach((column) => {
    const { key, showOutliers } = column;
    if (showOutliers) {
      outlierMap[key] = {};
    }
  });

  if (Object.keys(outlierMap).length) {
    sortedRows.forEach((row) => {
      for (const [key, value] of Object.entries(row)) {
        if (outlierMap[key]) {
          const sValue =
            value !== undefined && value !== null && value !== ''
              ? `${value}`
              : NOT_AVAILABLE;
          if (!outlierMap[key][sValue]) {
            outlierMap[key][sValue] = 0;
          }
          outlierMap[key][sValue]++;
        }
      }
    });
    Object.keys(outlierMap).forEach((key) => {
      const total = Object.values(outlierMap[key]).reduce((a, b) => a + b, 0);
      let hasMajority = false;
      Object.entries(outlierMap[key]).forEach((entry) => {
        const [value, count] = entry;
        if (count / total > 0.5) {
          hasMajority = true;
          delete outlierMap[key][value];
        }
      }, undefined);
      if (!hasMajority) {
        delete outlierMap[key];
      }
    });
  }

  const onExportClick = (event, done) => {
    const data = rows;
    data.map((dt) => {
      return Object.keys(dt).map((key) => {
        if (isObject(dt[key])) {
          dt[key] = dt[key].display;
        }
        return true;
      });
    });
    setExportData(data);
    done(true);
  };

  const titleClasses = classnames(
    'title_2',
    styles.titleContainer,
    titleClassName,
  );

  return (
    <Row className={classnames(styles.root, className)} noGutters>
      <Col style={{ height: '100%' }} data-cy-class="SkyPulseTable">
        <Row>
          <Col>
            {!title ? null : (
              <Row
                data-cy-class="SkyPulseTable-Title"
                className={styles.titleRow}
                noGutters
              >
                <Col className={titleClasses}>{title}</Col>
              </Row>
            )}
          </Col>
          {!allowExport ? null : (
            <Col md="auto">
              <CSVLink
                data={exportData}
                headers={columns.map((a) => ({ label: a.name, key: a.key }))}
                className={styles.exportButton}
                data-cy-id="SkyPulseTable-ExportButton"
                filename={`${exportFileName}.csv`}
                asyncOnClick={true}
                onClick={onExportClick}
              >
                Export
              </CSVLink>
            </Col>
          )}
        </Row>
        <Row
          data-cy-class="SkyPulseTable-Body"
          className={classnames(
            styles.tableRow,
            title ? styles.withTitle : null,
            tableClassname,
          )}
          noGutters
        >
          <Col style={{ height: '100%' }}>
            {!showHeaders ? null : (
              <Row
                className={classnames(
                  styles.row,
                  styles.headersRow,
                  headerRowClassname,
                )}
                data-cy-class="SkyPulseTable_headerRow"
                noGutters
              >
                {!sortedRows.length ? (
                  <Col
                    data-cy-class="SkyPulseTable_headerColumn"
                    className={styles.column}
                  >
                    {NOT_APPLICABLE}
                  </Col>
                ) : (
                  columns.map((column, i) => {
                    const { key, name, className, size, sortable, onToggle } =
                      column;
                    if (onToggle) {
                      return (
                        <div
                          key={i}
                          data-cy-class="SkyPulseTable_headerColumn"
                          className={classnames(
                            styles.column,
                            sortable ? styles.sortable : null,
                            className ? className : getSizeClass(size),
                          )}
                          onClick={onToggle}
                        >
                          <ButtonItem type="checkbox" />
                          {name}
                        </div>
                      );
                    }
                    return !className && !size ? (
                      <Col
                        key={i}
                        data-cy-class="SkyPulseTable_headerColumn"
                        className={classnames(
                          styles.column,
                          sortable ? styles.sortable : null,
                        )}
                        onClick={sortable ? () => setSort(key) : undefined}
                      >
                        {name}
                        {!sortable ||
                        !sortState ||
                        sortState.key !== key ? null : (
                          <span>
                            {' '}
                            <img
                              src={
                                sortState.dir === 'ascending'
                                  ? upFilledArrow
                                  : downFilledArrow
                              }
                              alt=""
                              className={styles.ascdesIcon}
                            />{' '}
                          </span>
                        )}
                      </Col>
                    ) : (
                      <div
                        key={i}
                        data-cy-class="SkyPulseTable_headerColumn"
                        className={classnames(
                          styles.column,
                          sortable ? styles.sortable : null,
                          className ? className : getSizeClass(size),
                        )}
                        onClick={sortable ? () => setSort(key) : undefined}
                      >
                        {name}
                        {!sortState || sortState.key !== key ? null : (
                          <span>
                            {' '}
                            <img
                              src={
                                sortState.dir === 'ascending'
                                  ? upFilledArrow
                                  : downFilledArrow
                              }
                              alt=""
                              className={styles.ascdesIcon}
                            />{' '}
                          </span>
                        )}
                      </div>
                    );
                  })
                )}
              </Row>
            )}
            {
              <Row
                className={classnames(
                  styles.rowContainer,
                  showHeaders ? styles.withHeaders : null,
                  styles.tableBorder,
                )}
                onClick={handleRowClick}
                noGutters
              >
                <Col>
                  {onScrollStart && <div ref={firstElementRef}></div>}
                  {sortedRows.map((row, i) => {
                    const { id } = row;
                    const rowClasses = classnames(
                      props.onRowClick ? styles.allowAction : null,
                      styles.row,
                      selectedId && selectedId === id ? styles.selected : null,
                    );

                    return (
                      <Row
                        key={i}
                        id={id}
                        className={rowClasses}
                        data-cy-class="SkyPulseTable_row"
                        noGutters
                      >
                        {columns.map((column, ii) => {
                          const { key, className, size } = column;
                          const displayValue =
                            isObject(row[key]) && row[key].display
                              ? row[key].display
                              : row[key];
                          const value =
                            displayValue !== undefined &&
                            displayValue !== null &&
                            displayValue !== ''
                              ? displayValue
                              : NOT_AVAILABLE;
                          const isOutlier =
                            outlierMap[key] && outlierMap[key][value]
                              ? true
                              : false;
                          const columnClasses = classnames(
                            styles.column,
                            className ? className : getSizeClass(size),
                            isOutlier ? styles.outlier : '',
                          );

                          return !className && !size ? (
                            <Col
                              key={ii}
                              data-cy-class={column.dataCyClassName}
                              className={classnames(
                                styles.column,
                                isOutlier ? styles.outlier : '',
                              )}
                            >
                              {value}
                            </Col>
                          ) : (
                            <div
                              key={ii}
                              data-cy-class={column.dataCyClassName}
                              className={columnClasses}
                            >
                              {value}
                            </div>
                          );
                        })}
                      </Row>
                    );
                  })}
                  {onScrollEnd && <div ref={lastElementRef}></div>}
                  {isLoading && (
                    <Row className={styles.loadingRow} noGutters>
                      <LoadingSpinner />
                    </Row>
                  )}
                </Col>
              </Row>
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

SkyPulseTable.defaultProps = {
  showHeaders: true,
  exportFileName: 'skypulse',
};

SkyPulseTable.propTypes = {
  allowExport: PropTypes.bool,
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  defaultSort: PropTypes.string,
  defaultSortState: PropTypes.oneOf(['ascending', 'descending']),
  exportFileName: PropTypes.string,
  hasMoreData: PropTypes.bool,
  headerRowClassname: PropTypes.string,
  isLoading: PropTypes.bool,
  onRowClick: PropTypes.func,
  onScrollEnd: PropTypes.func,
  onScrollStart: PropTypes.func,
  rows: PropTypes.array.isRequired,
  selectedId: PropTypes.string,
  showHeaders: PropTypes.bool,
  updateSortParameters: PropTypes.func,
  tableClassname: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  titleClassName: PropTypes.string,
};

export default SkyPulseTable;
