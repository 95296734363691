import { LOPA } from 'models';

export function getUniqueLRUPositions(aircraftTypes, lruTypeId) {
  const lopaTypes = LOPA.getLRUDeviceMap()[lruTypeId];
  const posArr = [];
  aircraftTypes.map((at) => {
    lopaTypes.map((lt) => {
      at.value.lopa[lt].map((type) => {
        const isDuplicate = posArr.find((p) => p === type.id);
        if (!isDuplicate) {
          posArr.push(type.id);
        }
        return false;
      });
      return false;
    });
    return false;
  });
  return posArr;
}
