import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import classnames from 'classnames';

import { LRUFactory } from 'classes';
import { PATHS, FILTER_EVENT_TYPE_OPTIONS } from 'constants';
import { getUTCDateTime } from 'utils';
import ButtonList from 'components/inputs/ButtonList';

import { setActiveAircraft, setActiveFlight } from 'actions';

import { Row, Col, Button } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import EventTypeFilterOverlay from 'components/overlays/EventTypeFilterOverlay';
import Overlay from 'containers/Overlay';

import CrewPanels from 'view/components/overlays/LRUDetail/CrewPanels';
import PassengerDisplays from 'view/components/overlays/LRUDetail/PassengerDisplays';
import PowerSupplies from 'view/components/overlays/LRUDetail/PowerSupplies';
import PublicDisplays from 'view/components/overlays/LRUDetail/PublicDisplays';
import PCUs from 'view/components/overlays/LRUDetail/PCUs';
import Servers from 'view/components/overlays/LRUDetail/Servers';
import ContentLoaders from 'view/components/overlays/LRUDetail/ContentLoaders';
import WAPs from 'view/components/overlays/LRUDetail/WAPs';

import styles from './styles.module.css';

const idPrefix = 'LRUDetail';

const columnDefinitions = [
  {
    name: 'UTC',
    key: 'utc',
    sortable: true,
    size: 'small',
    dataCyClassName: 'LruDetailsOverlay-EventTableColumn-Utc',
  },
  {
    name: 'Flight',
    key: 'flight',
    sortable: true,
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-EventTableColumn-Flight',
  },
  {
    name: 'Event',
    key: 'event',
    sortable: true,
    size: 'xlarge',
    dataCyClassName: 'LruDetailsOverlay-EventTableColumn-Event',
  },
];

const LruDetail = (props) => {
  const {
    rAircraftList,
    rFlightList,
    setActiveAircraft,
    setActiveFlight,
    push,
    id,
    isOpen,
    onClose,
  } = props;

  const eventsFilter = {};
  FILTER_EVENT_TYPE_OPTIONS.forEach((item) => {
    eventsFilter[item.value] = true;
  });

  const [lruData, setData] = useState(null);
  const [selectedEventFilter, setSelectedEventFilter] = useState('allEvents');
  const [currentEventsFilter, setCurrentEventsFilter] = useState(eventsFilter);
  const [eventFilterOverlayCoordinates, setEventFilterOverlayCoordinates] =
    useState(null);
  const [selectedEvents, setSelectedEvents] = useState(eventsFilter);

  const eventFilterButtonRef = useRef();

  useEffect(() => {
    const getLRUData = async (id) => {
      const data = await LRUFactory.getLruById(id);
      setData(data);
    };

    if (id) {
      getLRUData(id);
    } else {
      setData(null);
    }
  }, [id]);

  const aircraft = rAircraftList?.find((ac) => ac.id === lruData?.aircraft_id);
  const lru_type = lruData?.lru_type.value;
  const ltid = lruData?.lru_type.id;
  const position = lruData?.position;
  const deviceType = lruData
    ? aircraft.lopa.getDeviceTypeByLRUTypePosition(ltid, position)
    : null;
  const disabled = lruData === null;

  const onRowClick = (eventId, buttonId) => {
    const event = lruData.events.find((event) => event.id === eventId);

    if (buttonId === 'flightButton') {
      const flight = rFlightList.find(
        (flight) => flight.id === event.flight_id,
      );

      if (flight) {
        setActiveAircraft(aircraft);
        setActiveFlight(flight);
        push(`${PATHS.flights}`);
      }
    }
  };

  const onCloseOverlay = () => {
    const newFilters = {};
    FILTER_EVENT_TYPE_OPTIONS.forEach((item) => {
      newFilters[item.value] = true;
    });
    setSelectedEvents(newFilters);
    setCurrentEventsFilter(newFilters);
    setSelectedEventFilter('allEvents');
    onClose();
  };

  const renderOverview = () => {
    switch (deviceType) {
      case 'waps':
        return <WAPs lruData={lruData} aircraft={aircraft} />;
      case 'power_supplies':
        return <PowerSupplies lruData={lruData} aircraft={aircraft} />;
      case 'passenger_displays':
        return <PassengerDisplays lruData={lruData} aircraft={aircraft} />;
      case 'public_displays':
        return <PublicDisplays lruData={lruData} aircraft={aircraft} />;
      case 'pcus':
        return <PCUs lruData={lruData} aircraft={aircraft} />;
      case 'crew_panels':
        return <CrewPanels lruData={lruData} aircraft={aircraft} />;
      case 'content_loaders':
        return <ContentLoaders lruData={lruData} aircraft={aircraft} />;
      case 'servers':
        return <Servers lruData={lruData} aircraft={aircraft} />;
      default:
        return (
          <Row
            data-cy-id="LruDetailsOverlay-overviewContainer"
            className={styles.overviewContainer}
            noGutters
          >
            <Col>
              <Row>
                <Col
                  data-cy-id="LruDetailsOverlay-overviewTableTitle"
                  className={styles.title}
                >
                  Overview
                </Col>
              </Row>
              <Row className={styles.overviewTable} noGutters>
                <Col>
                  <SkyPulseTable
                    tableClassname={styles.table}
                    rows={[]}
                    columns={[{ name: '--', key: '--', size: 'xlarge' }]}
                    isLoading={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        );
    }
  };

  const rowData = [];
  lruData?.events.forEach((event, i) => {
    if (currentEventsFilter[event?.event_type?.id]) {
      const {
        value: { default_message: defaultMessage },
      } = event.event_type;
      const displayMessage = event.message || defaultMessage;
      const flight = rFlightList?.find(
        (flight) => flight.id === event.flight_id,
      );

      const flightNumber = flight
        ? flight.number === '--'
          ? 'NA'
          : flight.number
        : 'NA';

      const flightButton = (
        <div
          className={flight ? 'skypulsetable_toggle_button' : null}
          data-clickable-id={'flightButton'}
          data-cy-id="LruDetailsOverlay-FlightLinkButton"
        >
          {flightNumber}
        </div>
      );

      rowData.push({
        id: event.id,
        utc: { display: getUTCDateTime(event.created), sort: event.created },
        flight: { display: flightButton, sort: flightNumber },
        event: displayMessage,
      });
    }
  });

  const renderSelectedEvents = () => {
    if (selectedEventFilter !== 'specificEvents') {
      return '--';
    }
    const selectedEvents = FILTER_EVENT_TYPE_OPTIONS.filter(
      (item) => currentEventsFilter[item.value],
    );
    return `${selectedEvents.length} selected`;
  };

  let EventFilterOptions = [
    { name: 'All events', value: 'allEvents' },
    {
      name: (
        <div>
          Specific events
          <Button
            data-cy-id="LruDetailsOverlay-eventsFilterButton"
            ref={eventFilterButtonRef}
            onClick={() => {
              setSelectedEventFilter('specificEvents');
              onEventFilterPickerOpen(eventFilterButtonRef);
            }}
            disabled={disabled}
            className={classnames(
              'button_reg',
              'ml-2',
              styles.eventsFilterButton,
            )}
          >
            {renderSelectedEvents()}
          </Button>
        </div>
      ),
      value: 'specificEvents',
    },
  ];

  const isITUType = () => {
    return (
      lru_type === 'ITU (10.1")' ||
      lru_type === 'ITU (13.3")' ||
      lru_type === 'ITU (18.5")'
    );
  };

  if (isITUType()) {
    EventFilterOptions.splice(1, 0, {
      name: 'All events minus usage',
      value: 'allEventsMinusUsage',
    });
  }

  const onEventFilterPickerOpen = (ref) => {
    const { right, bottom } = ref.current.getBoundingClientRect();
    setEventFilterOverlayCoordinates({ x: right - 700, y: bottom });
  };

  const onEventFilterChange = (e) => {
    const { value } = e.target;
    switch (value) {
      case 'allEvents':
        selectAllEvents();
        break;
      case 'allEventsMinusUsage':
        selectAllMinusUsage();
        break;
      case 'specificEvents':
        selectSpecificEvents();
        break;
      default:
        break;
    }
  };

  const selectAllEvents = () => {
    const newFilters = {};
    FILTER_EVENT_TYPE_OPTIONS.forEach((item) => {
      newFilters[item.value] = true;
    });
    setSelectedEventFilter('allEvents');
    setCurrentEventsFilter(newFilters);
  };

  const selectAllMinusUsage = () => {
    const newFilters = {};
    FILTER_EVENT_TYPE_OPTIONS.forEach((item) => {
      if (item.value !== '5') {
        newFilters[item.value] = true;
      }
    });
    setSelectedEventFilter('allEventsMinusUsage');
    setCurrentEventsFilter(newFilters);
  };

  const selectSpecificEvents = () => {
    onEventFilterPickerOpen(eventFilterButtonRef);
  };

  const onEventsFilterDone = (filters) => {
    const newFilters = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        newFilters[key] = true;
      }
    });
    setSelectedEventFilter('specificEvents');
    setCurrentEventsFilter(newFilters);
    setEventFilterOverlayCoordinates(null);
  };

  const renderEvents = () => {
    return (
      <Row
        data-cy-id="LruDetailsOverlay-eventsContainer"
        className={styles.eventContainer}
        noGutters
      >
        <Col className="h-100">
          <Row className={styles.tableTitle} noGutters>
            <Col
              data-cy-id="LruDetailsOverlay-eventsTableTitle"
              className={styles.title}
            >
              {' '}
              Events - Last 30 days{' '}
            </Col>
            <Col md="auto">
              <ButtonList
                idPrefix={`${idPrefix}-Events-filter`}
                name="eventFilters"
                type="radio"
                onChange={onEventFilterChange}
                options={EventFilterOptions}
                value={selectedEventFilter}
                arrangementClassName={styles.filterList}
                disabled={disabled}
                classname={styles.options}
                testIdPrefix="LruDetailsOverlay"
              />
            </Col>
          </Row>
          <Row className={styles.eventsTable} noGutters>
            <Col className="h-100">
              <SkyPulseTable
                columns={columnDefinitions}
                rows={rowData}
                onRowClick={onRowClick}
                defaultSort="utc"
                isLoading={!lruData}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const Title = () => {
    return (
      <Row className={styles.header} noGutters>
        <Col data-cy-id="LruDetailOverlay-Header">
          {!aircraft
            ? null
            : `Tail ${aircraft?.tail} | ${aircraft?.aircraft_type.value.name} | ${lruData?.lru_type.value} ${lruData?.position}`}
        </Col>
        <Col className={styles.buttonContainer}>
          <Button
            className="button_reg"
            disabled={disabled}
            data-cy-id="LruDetailOverlay-LRUDetailsReportButton"
            onClick={() => push(`${PATHS.reportLruDetail}/${id}`)}
          >
            LRU details reports
          </Button>
          &nbsp;
          <Button
            data-cy-id="LRU-CloseButton"
            className="button_foc"
            onClick={onCloseOverlay}
            disabled={disabled}
          >
            Close
          </Button>
        </Col>
      </Row>
    );
  };

  const Body = () => {
    return (
      <>
        <Row className={styles.body} noGutters>
          <Col className="h-100">
            {renderOverview()}
            {renderEvents()}
          </Col>
        </Row>
        <EventTypeFilterOverlay
          position={eventFilterOverlayCoordinates}
          isOpen={eventFilterOverlayCoordinates !== null}
          onCancel={() => {
            setSelectedEvents(currentEventsFilter);
            setEventFilterOverlayCoordinates(null);
          }}
          onDone={onEventsFilterDone}
          selectedEvents={selectedEvents}
          setSelectedEvents={(filters) => setSelectedEvents(filters)}
        />
      </>
    );
  };

  return (
    <Overlay
      data-cy-id="LruDetailOverlay"
      contentClassName={styles.overlayRoot}
      headerClassName="w-100"
      bodyClassName="h-100"
      title={<Title />}
      isOpen={isOpen}
      showClose={false}
      onCancel={onCloseOverlay}
    >
      {<Body />}
    </Overlay>
  );
};

const state = (state) => {
  return {
    rAircraftList: state.aircraftList,
    rFlightList: state.flightList,
  };
};

const actions = {
  push,
  setActiveAircraft,
  setActiveFlight,
};

export default connect(state, actions)(React.memo(LruDetail));
