import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';
import classnames from 'classnames';

const ButtonItem = (props) => {
  const {
    classname,
    disabled,
    error,
    idPrefix,
    inline,
    inputRef,
    isChecked,
    label,
    labelClassName,
    name,
    onChange,
    showReverse,
    type,
    value,
    ...passProps
  } = props;

  const id = `${idPrefix}-ButtonItem-Input`;

  return (
    <Form.Check
      inline={inline}
      className={classnames(
        `${styles.root} ${showReverse ? styles.flip : ''}`,
        classname,
      )}
      id={id}
    >
      <Form.Check.Input
        type={type}
        isInvalid={error}
        name={name}
        disabled={disabled}
        onChange={onChange}
        value={value}
        checked={isChecked}
        ref={inputRef}
        {...passProps}
      />
      <Form.Check.Label
        className={`${styles.label} ${labelClassName} ${error ? 'labelError' : ''}`}
        htmlFor={id}
      >
        {label}
      </Form.Check.Label>
      {!error ? null : (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </Form.Check>
  );
};

ButtonItem.propTypes = {
  classname: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  idPrefix: PropTypes.string,
  inline: PropTypes.bool,
  inputRef: PropTypes.object,
  isChecked: PropTypes.bool,
  label: PropTypes.any,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  showReverse: PropTypes.bool,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ButtonItem;
