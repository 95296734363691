import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import config from 'config';
import store from 'store';

import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/base.css';

import { toggleDebug } from 'actions';
import App from 'apps/Default';
import StandardLayout from 'layouts/StandardLayout';
import { AuthProvider } from 'libs/oidc';

require('@skypulse/common/dist/shims.js');

document.onkeydown = (e) => {
  if (
    e.ctrlKey && // control is pressed
    e.shiftKey && // shift is pressed
    e.key === 'Q' // Q is pressed
  ) {
    store.dispatch(toggleDebug);
  }
};

const { configuration } = store.getState();

ReactDOM.render(
  <Provider store={store}>
    <StandardLayout>
      {configuration.ff_global_auth ? (
        <AuthProvider {...config.globalAuth}>
          <App />
        </AuthProvider>
      ) : (
        <App />
      )}
    </StandardLayout>
  </Provider>,
  document.getElementById('root'),
);
