import {
  DFPAPILambdaJSONResponse,
  DFPAPILambdaJSONErrorResponse,
} from 'classes';

export function defaultMock() {
  return new DFPAPILambdaJSONResponse();
}

export function defaultMockError(e) {
  return new DFPAPILambdaJSONErrorResponse(e);
}

export * from './aircraft';
export * from './flight';
export * from './configuration';
