import React from 'react';
import PropTypes from 'prop-types';

import { Aircraft, LRU } from 'models';

import { Row, Col } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const overviewCol = [
  {
    name: 'Tail',
    key: 'tail',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Tail',
  },
  {
    name: 'LRU',
    key: 'lru',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Lru',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Pos',
  },
  {
    name: 'Manifest',
    key: 'manifest',
    size: 'large',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Manifest',
  },
];

const Servers = (props) => {
  const { lruData, aircraft } = props;

  const latestLRUHistory = lruData.getLatestHistory();
  let sw_part_number;
  if (latestLRUHistory?.software_version) {
    const {
      software_version: { PN },
    } = latestLRUHistory;
    sw_part_number = PN;
  }

  const overviewData = [
    {
      tail: aircraft?.tail,
      lru: lruData.lru_type.value,
      pos: lruData.position,
      manifest: sw_part_number,
    },
  ];

  return (
    <Row
      data-cy-id="LruDetailsOverlay-overviewContainer"
      className={styles.overviewContainer}
      noGutters
    >
      <Col>
        <Row>
          <Col
            data-cy-id="LruDetailsOverlay-overviewTableTitle"
            className={styles.title}
          >
            Overview
          </Col>
        </Row>
        <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable columns={overviewCol} rows={overviewData} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Servers.propTypes = {
  lruData: PropTypes.instanceOf(LRU).isRequired,
  aircraft: PropTypes.instanceOf(Aircraft),
};

export default Servers;
