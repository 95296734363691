import { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Aircraft, Flight } from 'models';
import {
  getAircraftStatusFromHealthScore,
  getHealthColorFromAircraftStatus,
} from 'utils';
import { AIRCRAFT_STATUS_OFFLINE } from 'constants';

const AircraftRoute = ({ map, activeAircraft, activeFlight }) => {
  const polylines = useRef([]);

  const clearPaths = () => {
    polylines.current.forEach((item) => {
      item.ref.setMap(null);
    });
    polylines.current.length = 0;
  };

  useEffect(() => {
    clearPaths();
    if (
      !activeAircraft ||
      !activeFlight ||
      activeAircraft?.status === AIRCRAFT_STATUS_OFFLINE
    ) {
      return;
    }

    const flight = activeAircraft.getCurrentFlight();
    if (!flight || flight.id !== activeFlight.id) {
      return;
    }

    const history = activeFlight.history;
    if (!history.length) {
      return;
    }

    let prevState = getAircraftStatusFromHealthScore(
      history[0].aircraft_health_score,
    );
    let paths = [];
    history.forEach((item, index) => {
      const currentState = getAircraftStatusFromHealthScore(
        item.aircraft_health_score,
      );
      if (currentState !== prevState) {
        polylines.current.push({ paths, type: prevState });
        paths = [];
      }
      paths.push({ lat: item.latitude, lng: item.longitude });
      if (index === history.length - 1) {
        polylines.current.push({ paths, type: currentState });
      }
      prevState = currentState;
    });
    const circleSymbol = {
      path: window.google.maps.SymbolPath.CIRCLE,
      strokeOpacity: 1,
    };

    polylines.current.forEach((state, index) => {
      const p = new window.google.maps.Polyline({
        path:
          index < polylines.current.length - 1
            ? [...state.paths, polylines.current[index + 1].paths[0]]
            : state.paths,
        strokeColor: getHealthColorFromAircraftStatus(state.type),
        geodesic: true,
        strokeOpacity: 1.0,
        strokeWeight: 3,
        icons:
          index === 0
            ? [
                {
                  icon: circleSymbol,
                  offset: '0',
                },
              ]
            : [],
      });
      p.setMap(map);
      state.ref = p;
    });
  }, [activeAircraft, activeFlight, map]);

  return null;
};

AircraftRoute.propTypes = {
  activeAircraft: PropTypes.instanceOf(Aircraft),
  activeFlight: PropTypes.instanceOf(Flight),
  map: PropTypes.object.isRequired,
};

export default memo(AircraftRoute);
