import React from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { PATHS } from 'constants';

import Overlay from 'containers/Overlay';

import { Button, Col, Row } from 'react-bootstrap';

const OVERLAY_WIDTH = 400;

const idPrefix = 'LRU-Overlay';

const LruOverlay = (props) => {
  const {
    deviceType,
    isOpen,
    onClose,
    position,
    lruType,
    handleDetailClick,
    rightCorner,
    push,
  } = props;

  const x = position?.x - OVERLAY_WIDTH;
  const y = position?.y;

  const title = !lruType
    ? ''
    : lruType.type
      ? `${lruType.type} ${lruType.pos}`
      : lruType.pos;

  const handleConfigurationClick = (lruType) => {
    onClose();
    push(`${PATHS.ship}/${deviceType}/${lruType.pos}`);
  };

  const Footer = () => {
    return (
      <>
        <Row className={styles.footer} noGutters>
          <Col md="auto">
            <Button
              data-cy-id={`${idPrefix}-Cancel-Button`}
              className={classnames('button_reg')}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Col>
          <Col md="auto">
            <Button
              data-cy-id={`${idPrefix}-Configuration-Button`}
              className={classnames('button_reg')}
              onClick={() => handleConfigurationClick(lruType)}
            >
              Configuration
            </Button>
          </Col>
          <Col md="auto">
            <Button
              data-cy-id={`${idPrefix}-LRU-Details-Button`}
              className={classnames('button_reg')}
              onClick={() => handleDetailClick(lruType)}
            >
              LRU Details
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Overlay
      data-cy-id={`${idPrefix}-Message-Window`}
      contentClassName={styles.root}
      footer={<Footer />}
      isOpen={isOpen}
      onCancel={onClose}
      position={rightCorner && position ? { x, y } : position}
      showClose={false}
      title={title}
    />
  );
};

Overlay.propTypes = {
  deviceType: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  lruType: PropTypes.object,
  onClose: PropTypes.func,
  position: PropTypes.object,
  rightCorner: PropTypes.bool,
};

const lruPropsAreEqual = (prevLRU, nextLRU) => {
  return prevLRU.isOpen === nextLRU.isOpen;
};

export default React.memo(LruOverlay, lruPropsAreEqual);
