import { FETCH_KIOSK_LRUS, FETCH_KIOSK_LRU_DETAIL } from 'actions';

export function top5LRUTypeCollection(state = {}, action) {
  switch (action.type) {
    case FETCH_KIOSK_LRUS:
      return action.top5LRUTypeCollection;
    default:
      return state;
  }
}

export function top5LRUDetail(state = {}, action) {
  switch (action.type) {
    case FETCH_KIOSK_LRU_DETAIL:
      return action.top5LRUDetail;
    default:
      return state;
  }
}
