import { ROLES, PERMISSIONS, RESOURCES, SCOPES } from 'constants';

export const evaluatePermissions = (
  user,
  allowedRoles,
  forbiddenRoles,
  permissions = undefined,
) => {
  // TODO: Refactor to translate roles into more permissions
  // this functionality was always meant to operate on permissions

  // // if the user belongs to one of the forbidden roles then deny access
  // if (forbiddenRoles && forbiddenRoles.some((rid) => {
  //   const role = ROLES.getEnumerationItem(rid);
  //   return !role || user.roles.indexOf(role.id) > -1;
  // } )) {
  //   return false;
  // }

  // if the user belongs to one of the allowed roles then allow access
  if (
    allowedRoles &&
    allowedRoles.some((rid) => {
      const role = ROLES.getEnumerationItem(rid);
      return role && user.roles.indexOf(role.id) > -1;
    })
  ) {
    return true;
  }

  if (permissions) {
    return permissions.some((pid) => {
      const permission = PERMISSIONS.getEnumerationItem(pid);
      if (!permission) {
        return false;
      }
      return user?.roles.some((rid) => {
        const role = ROLES.getEnumerationItem(rid);
        return role?.value.permissions.some((rpid) => {
          const rolePermission = PERMISSIONS.getEnumerationItem(rpid);
          return rolePermission.id === permission.id;
        });
      });
    });
  }

  return true;
};

// debugging function
export const renderPermissions = (user) => {
  return (
    <div>
      {user.roles.map((rid) => {
        const role = ROLES.getEnumerationItem(rid);
        return (
          <>
            <p>{role.value.name}</p>
            <ul>
              {role.value.permissions.map((rpid) => {
                const permission = PERMISSIONS.getEnumerationItem(rpid);
                const resource = RESOURCES.getEnumerationItem(
                  permission.value.resource,
                );
                const scope = SCOPES.getEnumerationItem(permission.value.scope);
                console.log(permission, resource, scope);
                return (
                  <li>
                    {permission.value.name} ({permission.id}):{' '}
                    {resource.value.name} -- {scope.value.name}
                  </li>
                );
              })}
            </ul>
          </>
        );
      })}
    </div>
  );
};
