import {
  CLEAR_ACTIVE_FLIGHT,
  SET_ACTIVE_FLIGHT,
  UPDATE_ACTIVE_FLIGHT,
  UPDATE_FLIGHT_LIST,
} from 'actions';

export function activeFlight(state = {}, action) {
  switch (action.type) {
    case SET_ACTIVE_FLIGHT:
    case UPDATE_ACTIVE_FLIGHT:
      return action.activeFlight;
    case CLEAR_ACTIVE_FLIGHT:
      return null;
    default:
      return state;
  }
}

export function flightList(state = [], action) {
  switch (action.type) {
    case UPDATE_FLIGHT_LIST:
      // extract the new flight list from action
      const { flightList } = action;

      // if state is already populated and the new list has no entries
      if (state && flightList.length < 1) {
        return state;
      }

      // derive new state from old state
      let newState =
        state && Array.isArray(state) && state.length ? [...state] : [];

      // loop existing state and check for any updates
      newState.forEach((flight, index) => {
        const newIndex = flightList.findIndex((f) => f.id === flight.id);
        if (newIndex > -1) {
          newState[index] = flightList[newIndex];
          flightList.splice(newIndex, 1);
        }
      });

      // the remaining flights are new so concat
      if (flightList.length) {
        newState = newState.concat(flightList);
      }

      // return the result
      return newState;
    default:
      return state;
  }
}
