import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SkyPulseTable from 'components/SkyPulseTable';
import ButtonItem from 'components/inputs/ButtonItem';
import { User } from 'models';
import { NOTIFICATION_TYPES } from 'constants';
import Overlay from 'containers/Overlay';
import iconCheckAll from 'assets/icons/iconCheckAll.png';
import iconCheckNone from 'assets/icons/iconCheckNone.png';
import { Button, Col, Row } from 'react-bootstrap';

import styles from './styles.module.css';

const notifications = [];
NOTIFICATION_TYPES.getSortedEnumerationItems().forEach((notification) => {
  notifications.push({
    id: notification.id,
    title: `${notification.value.code}: ${notification.value.short_description}`,
    value: notification.value.code,
  });
});

const EditNotififcationSubscriptionsOverlay = (props) => {
  const { user, group, isOpen, onCancel, onDone } = props;

  const [currentFilters, setCurrentFilters] = useState({});

  useEffect(() => {
    if (isOpen) {
      const daily = {};
      const realtime = {};
      const subscriptions = user?.subscriptions || group?.subscriptions;
      NOTIFICATION_TYPES.getSortedEnumerationItems().forEach((notification) => {
        const subscription = subscriptions.find(
          (sub) => sub.notification_type.id === notification.id,
        );
        daily[notification.id] = !subscription?.daily ? false : true;
        realtime[notification.id] = !subscription?.realtime ? false : true;
      });
      setCurrentFilters({
        daily,
        realtime,
      });
    }
  }, [isOpen, user, group]);

  const onChange = (key, filterIndex) => {
    const newFilters = { ...currentFilters };
    newFilters[filterIndex][key] = !newFilters[filterIndex][key];
    setCurrentFilters(newFilters);
  };

  const selectAll = (filterIndex) => {
    const newFilters = { ...currentFilters };
    NOTIFICATION_TYPES.getSortedEnumerationItems().forEach((notification) => {
      newFilters[filterIndex][notification.id] = true;
    });
    setCurrentFilters(newFilters);
  };

  const selectNone = (filterIndex) => {
    const newFilters = { ...currentFilters };
    NOTIFICATION_TYPES.getSortedEnumerationItems().forEach((notification) => {
      newFilters[filterIndex][notification.id] = false;
    });
    setCurrentFilters(newFilters);
  };

  const columnDefinitions = [
    {
      name: 'Alert',
      key: 'alert',
    },
    {
      name: (
        <Row className={styles.header} noGutters>
          <div> Real time </div>
          <div>
            <img
              src={iconCheckAll}
              alt=""
              className={styles.icon}
              onClick={() => selectAll('realtime')}
            />
            <img
              src={iconCheckNone}
              alt=""
              className={styles.icon}
              onClick={() => selectNone('realtime')}
            />
          </div>
        </Row>
      ),
      key: 'real_time',
      size: 'xsmall',
    },
    {
      name: (
        <Row className={styles.header} noGutters>
          <div> Nightly </div>
          <div>
            <img
              src={iconCheckAll}
              alt=""
              className={styles.icon}
              onClick={() => selectAll('daily')}
            />
            <img
              src={iconCheckNone}
              alt=""
              className={styles.icon}
              onClick={() => selectNone('daily')}
            />
          </div>
        </Row>
      ),
      key: 'nightly',
      size: 'xsmall',
    },
  ];

  const Footer = () => {
    return (
      <Row className={styles.footer} noGutters>
        <Col lg="auto">
          <Button
            className="button_call"
            data-cy-id={`edit-alerts-Overlay-cancelButton`}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Col>
        <Col lg="auto">
          <Button
            className="button_call"
            data-cy-id={`edit-alerts-Overlay-DoneButton`}
            onClick={() => onDone(currentFilters)}
          >
            Done
          </Button>
        </Col>
      </Row>
    );
  };

  if (!user && !group) {
    return null;
  }

  const data = [];
  notifications.forEach((alert) => {
    const { id, title, value } = alert;
    data.push({
      id: id,
      alert: title,
      real_time: (
        <ButtonItem
          value={value}
          type="checkbox"
          classname={styles.checkboxButton}
          isChecked={
            (currentFilters?.realtime && currentFilters.realtime[id]) || false
          }
          onChange={() => onChange(id, 'realtime')}
        />
      ),
      nightly: (
        <ButtonItem
          value={value}
          type="checkbox"
          classname={styles.checkboxButton}
          isChecked={
            (currentFilters?.daily && currentFilters?.daily[id]) || false
          }
          onChange={() => onChange(id, 'daily')}
        />
      ),
    });
  });

  return (
    <Overlay
      data-cy-id="edit-notifications-Overlay"
      contentClassName={styles.overlayRoot}
      headerClassName="w-100"
      bodyClassName={styles.bodyClassName}
      title={
        user
          ? `User profile: ${user.first_name} ${user.last_name}`
          : `Group profile: ${group.name}`
      }
      isOpen={isOpen}
      footer={<Footer />}
      showClose={false}
      onCancel={() => {
        onCancel();
      }}
    >
      <Row className={styles.root} noGutters>
        <Col className={styles.tableContainer}>
          {!user ? null : (
            <div className={styles.emailText}>Email: {user.email}</div>
          )}
          <SkyPulseTable
            className={styles.tablestyles}
            columns={columnDefinitions}
            rows={data}
            headerRowClassname={styles.tableHeader}
            title="Notification Preferences"
          />
        </Col>
      </Row>
    </Overlay>
  );
};

EditNotififcationSubscriptionsOverlay.propTypes = {
  user: PropTypes.instanceOf(User),
  group: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default EditNotififcationSubscriptionsOverlay;
