import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Aircraft, Flight } from 'models';
import { getLopaBodyType } from 'utils';

import { Col, Row } from 'react-bootstrap';
import AircraftDetailsPanel from 'components/default/AircraftDetailsPanel';
import FlightEventsPanel from 'components/FlightEventsPanel';
import LOPA from 'components/LOPA';
import FlightStatistics from './FlightStatistics';
import LoadingSpinner from 'components/LoadingSpinner';

import styles from './styles.module.css';
import classNames from 'classnames';

const FlightOverview = (props) => {
  const { aircraft, mode: currentMode, flight, push } = props;
  const isFlightOpen = flight.is_open ? true : false;

  const [mode, setMode] = useState('faults');
  const [isLatest, setLatest] = useState(isFlightOpen ? true : false);
  const [statType, setStatType] = useState('count');
  const [faultsData, setFaultsData] = useState(null);

  useEffect(() => {
    setFaultsData(flight.getFlightFaultsStats(aircraft));
  }, [aircraft, flight]);

  const { lopa: aircraftLopa, aircraft_type: aircraftType } = aircraft;
  const totalLRUs = aircraftLopa.getDeviceCount('all', 'pcus');
  let totalITUs = aircraftLopa.getDeviceCount('passenger_displays');

  if (!faultsData) {
    return <LoadingSpinner />;
  }

  // Note: CurrentMode will also take precedence than state variable mode
  // Using currentMode (mode passed as a prop) for kiosk mode where modes are based on the url parameter.
  // Where as mode (state variable) is used to set the mode based on the click events on flight statistics component

  return (
    <div
      className={classNames(
        styles.flightWrapper,
        getLopaBodyType(aircraftType.value.name),
      )}
    >
      <LOPA
        mode={currentMode || mode}
        data={faultsData.lopaData}
        aircraft={aircraft}
        push={push}
        isLatest={isLatest}
        key={flight.id}
      />
      <Row className={classNames(styles.infoRow)} noGutters>
        <Col md="auto" className={styles.overview}>
          <AircraftDetailsPanel
            aircraft={aircraft}
            flight={flight}
            view="flightDetail"
          />
        </Col>
        <Col md="auto" className={styles.lopaKey}>
          <FlightStatistics
            changeLatest={setLatest}
            changeMode={setMode}
            changeStatType={setStatType}
            currentMode={currentMode || mode}
            currentStatType={statType}
            data={faultsData.faultStats}
            totalLRUs={totalLRUs}
            totalITUs={totalITUs}
            isFlightOpen={isFlightOpen}
            isLatest={isLatest}
          />
        </Col>
        <Col className={styles.event}>
          <FlightEventsPanel
            key={flight.id}
            flight={flight}
            selectedMode={mode}
          />
        </Col>
      </Row>
    </div>
  );
};

FlightOverview.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  mode: PropTypes.string,
  flight: PropTypes.instanceOf(Flight).isRequired,
  push: PropTypes.func,
};

export default FlightOverview;
