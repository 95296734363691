import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  setActiveAircraft,
  clearActiveAircraft,
  updateFlightList,
} from 'actions';
import { AircraftFactory } from 'classes';
import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  SELECT_OPTION_ALL,
  NOT_AVAILABLE,
} from 'constants';
import { getUTCDateTime } from 'utils';
import { goBack } from 'react-router-redux';
import { Row, Col, Button } from 'react-bootstrap';
import ButtonItem from 'components/inputs/ButtonItem';
import Select from 'components/inputs/Select';
import ShipSelect from 'components/default/ShipSelect';
import SkyPulseTable from 'components/SkyPulseTable';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const idPrefix = 'FlightSelect';

const defaultFilterType = 'fleet';
const defaultFleetOption = SELECT_OPTION_ALL.value;

const columns = [
  {
    name: 'Departure UTC',
    key: 'utc',
    sortable: true,
    className: styles.utcColumn,
    size: 'xsmall',
    dataCyClassName: 'FlightSelect-FlightsTableColumn-DepartureUtc',
  },
  {
    name: 'Tail',
    key: 'tail',
    sortable: true,
    size: 'xsmall',
    dataCyClassName: 'FlightSelect-FlightsTableColumn-Tail',
  },
  {
    name: 'Flight',
    key: 'number',
    sortable: true,
    size: 'xsmall',
    dataCyClassName: 'FlightSelect-FlightsTableColumn-Flight',
  },
  {
    name: 'Depart',
    key: 'dep',
    sortable: true,
    size: 'xsmall',
    dataCyClassName: 'FlightSelect-FlightsTableColumn-Depart',
  },
  {
    name: 'Arrive',
    key: 'des',
    sortable: true,
    size: 'xsmall',
    dataCyClassName: 'FlightSelect-FlightsTableColumn-Arrive',
  },
  {
    name: 'Status',
    key: 'status',
    sortable: true,
    size: 'xsmall',
    dataCyClassName: 'FlightSelect-FlightsTableColumn-Status',
  },
];

const FlightSelect = (props) => {
  const {
    rActiveAircraft,
    rAircraftList,
    rFlightList,
    onCancel,
    onSelect,
    goBack,
    setActiveAircraft,
    clearActiveAircraft,
    updateFlightList,
  } = props;

  const [filterType, setFilterType] = useState(
    rActiveAircraft ? 'tail' : defaultFilterType,
  );
  const [fleetOption, setFleetOption] = useState(defaultFleetOption);
  const [flightList, setFlightList] = useState([]);
  const [showShipSelect, setShowShipSelect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const tail = rActiveAircraft ? rActiveAircraft.tail : undefined;

  useEffect(() => {
    const updateList = async () => {
      setFlightList([]);
      setIsLoading(true);

      const flights = rFlightList.reduce((arr, flight) => {
        const aircraft = AircraftFactory.getSummaryAircraftById(
          flight.aircraft_id,
        );
        if (
          (filterType === 'fleet' &&
            (fleetOption === SELECT_OPTION_ALL.value ||
              (aircraft && aircraft.aircraft_type.id === fleetOption))) ||
          (filterType === 'tail' && aircraft && aircraft.tail === tail)
        ) {
          arr.push({
            id: flight.id,
            utc: {
              display: flight.departure_timestamp
                ? getUTCDateTime(flight.departure_timestamp)
                : NOT_AVAILABLE,
              sort: flight.departure_timestamp || 0,
            },
            tail: aircraft?.tail,
            number: flight.number,
            dep: flight.departure_airport?.value.iata,
            des: flight.destination_airport?.value.iata,
            status: flight.is_open ? 'Open' : 'Closed',
          });
        }
        return arr;
      }, []);
      setFlightList(flights);
      setIsLoading(false);
    };

    if (rFlightList) {
      updateList();
    }
  }, [rFlightList, filterType, fleetOption, tail, updateFlightList]);

  const handleFilterTypeChange = (e) => {
    const { value } = e.target;
    setFilterType(value);
  };

  const handleFleetSelectChange = ({ target: { value } }) => {
    setFilterType('fleet');
    setFleetOption(value);
  };

  // if the current filter is tail and no active aircraft
  if ((filterType === 'tail' && !rActiveAircraft) || showShipSelect) {
    return (
      <ShipSelect
        onCancel={() => {
          if (rActiveAircraft) {
            setShowShipSelect(false);
          } else {
            goBack();
          }
        }}
        onSelect={(aircraft) => {
          clearActiveAircraft();
          setActiveAircraft(aircraft);
          setShowShipSelect(false);
        }}
      />
    );
  }

  return (
    <Row data-cy-id="FlightSelect" className={styles.root} noGutters>
      <Col className={styles.body}>
        <Row className={styles.bodyHeaderRow} noGutters>
          <Col className="title_1">Flight selection</Col>
          <Col md="auto">
            <Button
              data-cy-id="FlightSelect-CancelButton"
              className="button_reg"
              onClick={onCancel}
            >
              {' '}
              Cancel{' '}
            </Button>
          </Col>
        </Row>
        <Row className={styles.fleetSelectionRow} noGutters>
          <Col md={2}>
            <ButtonItem
              label={
                <Select
                  data-cy-id="FlightSelect-FiltersPanel-FleetSelect"
                  idPrefix={idPrefix}
                  label="Fleet: "
                  onChange={handleFleetSelectChange}
                  options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
                  value={fleetOption}
                />
              }
              value="fleet"
              onChange={handleFilterTypeChange}
              type="radio"
              data-cy-id="FlightSelect-FiltersPanel-FleetInput"
              classname={styles.buttonItemLabel}
              isChecked={filterType === 'fleet'}
              idPrefix={`${idPrefix}-fleet`}
            />
          </Col>
          <Col md={2}>
            {rActiveAircraft ? (
              <ButtonItem
                label={
                  <div className={styles.buttonLabel}>
                    Tail {tail} &nbsp;
                    <Button
                      data-cy-id="FlightSelect-FiltersPanel-ChangeTailButton"
                      className="button_reg"
                      onClick={() => {
                        setShowShipSelect(true);
                        setFilterType('tail');
                      }}
                    >
                      {' '}
                      Change Tail{' '}
                    </Button>
                  </div>
                }
                data-cy-id="FlightSelect-FiltersPanel-TailInput"
                value="tail"
                onChange={handleFilterTypeChange}
                type="radio"
                classname={styles.buttonItemLabel}
                isChecked={filterType === 'tail'}
                idPrefix={`${idPrefix}-tail`}
              />
            ) : (
              <ButtonItem
                data-cy-id="FlightSelect-FiltersPanel-SelectTailInput"
                label="Select a tail"
                value="tail"
                onChange={handleFilterTypeChange}
                type="radio"
                classname={styles.buttonItemLabel}
                isChecked={filterType === 'tail'}
                idPrefix={`${idPrefix}-tail`}
              />
            )}
          </Col>
        </Row>
        <Row className={styles.flightListRow} noGutters>
          <Col md="auto" className={styles.tableContainer}>
            <SkyPulseTable
              title={
                <Row>
                  <Col>Flights</Col>
                  <Col className={styles.secondaryTitle}>
                    Timeframe
                    <div className={styles.titleDescription}>
                      {' '}
                      - Last 30 days
                    </div>
                  </Col>
                </Row>
              }
              columns={columns}
              rows={flightList}
              defaultSort="utc"
              defaultSortState="descending"
              isLoading={isLoading}
              onRowClick={(id) => {
                const flight = rFlightList.find((flight) => flight.id === id);
                if (!flight) {
                  console.error('Flight not found');
                } else {
                  const aircraft = rAircraftList.find(
                    (ac) => ac.id === flight.aircraft_id,
                  );
                  onSelect(flight, aircraft);
                }
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

FlightSelect.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const state = (state) => {
  return {
    rActiveAircraft: state.activeAircraft,
    rAircraftList: state.aircraftList,
    rFlightList: state.flightList,
  };
};

const actions = {
  goBack,
  setActiveAircraft,
  clearActiveAircraft,
  updateFlightList,
};

export default connect(state, actions)(FlightSelect);
