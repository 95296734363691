import React from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Container, Col, Row, Modal } from 'react-bootstrap';

const Overlay = (props) => {
  const {
    bodyClassName,
    children,
    contentClassName,
    dialogClassName,
    footer,
    headerClassName,
    isOpen,
    onCancel,
    position,
    showClose,
    title,
    ...others
  } = props;

  const dataIdPrefix = others['data-cy-id'];

  // Only move default position of modal if a coordinate is passed in
  const style = !position ? null : { left: position.x, top: position.y };

  return (
    <Modal
      animation={false}
      contentClassName={classnames(styles.content, contentClassName)}
      dialogClassName={classnames(
        dialogClassName,
        position ? 'm-0' : styles.position,
      )}
      show={isOpen}
      style={style}
      {...others}
    >
      {(title || showClose) && (
        <Modal.Header
          className={classnames('p-0', styles.header, headerClassName)}
        >
          <Modal.Title className={classnames('title_1', 'w-100')}>
            {title}
          </Modal.Title>
          {!showClose ? null : (
            <Button
              data-cy-id={`${dataIdPrefix}-CloseButton`}
              className="button_reg_blank"
              onClick={onCancel}
            >
              x
            </Button>
          )}
        </Modal.Header>
      )}
      {!children ? null : (
        <Modal.Body className={classnames('p-0', bodyClassName)}>
          <Container className={classnames('p-0', 'm-0', 'h-100')} fluid>
            <Row className="h-100" noGutters>
              <Col className="h-100">{children}</Col>
            </Row>
          </Container>
        </Modal.Body>
      )}
      {!footer ? null : (
        <Modal.Footer className={classnames('p-0', styles.footer)}>
          {footer}
        </Modal.Footer>
      )}
    </Modal>
  );
};

Overlay.defaultProps = {
  showClose: true,
};

Overlay.propTypes = {
  bodyClassName: PropTypes.string,
  children: PropTypes.object,
  contentClassName: PropTypes.string,
  dialogClassName: PropTypes.string,
  footer: PropTypes.object,
  headerClassName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  position: PropTypes.object,
  showClose: PropTypes.bool,
  title: PropTypes.string,
};

export default Overlay;
