import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { PATHS } from 'constants';
import { updateFlightList } from 'actions';

import { Col, Row } from 'react-bootstrap';
import LeftNav from 'components/default/LeftNav';
import Ship from './Ship';
import Flight from './Flight';
import LRUFaults from './LRUFaults';
import LRUDetail from './LRUDetail';
import Usage from './Usage';
import Availability from './Availability';
import ConfigurableFeatures from './ConfigurableFeatures';

import styles from './styles.module.css';

const Reports = (props) => {
  const {
    match: { params, path },
    rAircraftList,
    rFlightList,
    push,
    updateFlightList,
  } = props;

  const NavMap = {
    Create: [
      {
        display: 'Ship',
        id: 'ship',
      },
      {
        display: 'Flight',
        id: 'flight',
      },
      {
        display: 'LRUs & Faults',
        id: 'lrusFaults',
      },
      {
        display: 'LRU Details',
        id: 'lruDetails',
      },
      {
        display: 'Usage',
        id: 'usage',
      },
      {
        display: 'Compact faults',
        id: 'availability',
      },
      {
        display: 'Config features',
        id: 'configurableFeatures',
      },
    ],
  };

  const defaultPage = 'ship';

  const pathParams = path?.split('/');
  const selectedReport = pathParams[2] ? pathParams[2] : defaultPage;
  const id = params?.id;

  useEffect(() => {
    const updateList = async () => {
      const todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      const dt7DaysAgo = todayDate.getTime() - 7 * 24 * 60 * 60 * 1000;

      // only update flight list if no flights are older than 7 days old
      if (!rFlightList.find((flight) => flight.modified < dt7DaysAgo)) {
        const dt30DaysAgo = todayDate.getTime() - 30 * 24 * 60 * 60 * 1000;
        await updateFlightList(dt30DaysAgo);
      }
    };
    if (rFlightList) {
      updateList();
    }
  }, [rFlightList, updateFlightList]);

  const renderMenuItem = () => {
    switch (selectedReport) {
      case 'flight':
        return (
          <Flight
            aircraftList={rAircraftList}
            flightList={rFlightList}
            flightId={id}
          />
        );
      case 'lrusFaults':
        return <LRUFaults />;
      case 'lruDetails':
        return <LRUDetail aircraftList={rAircraftList} LruId={id} />;
      case 'usage':
        return (
          <Usage
            aircraftList={rAircraftList}
            flightList={rFlightList}
            flightId={id}
          />
        );
      case 'availability':
        return <Availability />;
      case 'configurableFeatures':
        return <ConfigurableFeatures />;
      case 'ship':
      default:
        return <Ship aircraftList={rAircraftList} aircraftId={id} />;
    }
  };

  return (
    <Row className={styles.root} data-cy-id="Reports" noGutters>
      <Col md="auto" className={styles.leftPanel}>
        <LeftNav
          activeMenuItem={!selectedReport ? 'availability' : selectedReport}
          changeActiveMenu={(id) => {
            push(`${PATHS.reports}/${id}`);
          }}
          navMap={NavMap}
          primaryHeader="Reports"
          testIdPrefix="Reports"
        />
      </Col>
      <Col className={styles.container}>{renderMenuItem()}</Col>
    </Row>
  );
};

const state = (state) => {
  return {
    rAircraftList: state.aircraftList,
    rFlightList: state.flightList,
  };
};

const actions = {
  push,
  updateFlightList,
};

export default connect(state, actions)(Reports);
