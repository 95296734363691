import {
  CLEAR_ACTIVE_AIRCRAFT,
  SET_ACTIVE_AIRCRAFT,
  SET_AIRCRAFT_FILTERS,
  UPDATE_ACTIVE_AIRCRAFT,
  UPDATE_AIRCRAFT_LIST,
} from 'actions';

export function activeAircraft(state = {}, action) {
  switch (action.type) {
    case SET_ACTIVE_AIRCRAFT:
    case UPDATE_ACTIVE_AIRCRAFT:
      return action.activeAircraft;
    case CLEAR_ACTIVE_AIRCRAFT:
      return null;
    default:
      return state;
  }
}

export function aircraftFilters(state = {}, action) {
  switch (action.type) {
    case SET_AIRCRAFT_FILTERS:
      return action.aircraftFilters;
    default:
      return state;
  }
}

export function aircraftList(state = [], action) {
  switch (action.type) {
    case UPDATE_AIRCRAFT_LIST:
      const { aircraftList } = action;

      // if state is already populated and the new list has no entries
      if (state && aircraftList.length < 1) {
        return state;
      }

      const newState = Array.isArray(state) ? [...state] : [];

      if (Array.isArray(aircraftList)) {
        aircraftList.forEach((updateAircraft) => {
          // determine if aircraft is already in list
          const currentIndex = newState.findIndex(
            (existingAircraft) => existingAircraft.id === updateAircraft.id,
          );

          // aircraft is in list
          if (currentIndex > -1) {
            // if the aircraft has not been deleted
            if (!updateAircraft.deleted) {
              // update newState with the updated aircraft
              newState[currentIndex] = updateAircraft;
            } else {
              // remove the aircraft from new state
              newState.splice(currentIndex, 1);
            }
            // aircraft is not in list and is not deleted
          } else if (!updateAircraft.deleted) {
            // add updateAircraft to newState
            newState.push(updateAircraft);
          }
        });
      }

      return newState;
    default:
      return state;
  }
}
