import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import {
  clearActiveAircraft,
  clearActiveFlight,
  setActiveAircraft,
  setAircraftFilters,
  setActiveFlight,
} from 'actions';
import { FlightFactory } from 'classes';
import {
  AIRCRAFT_STATUS_OFFLINE,
  AIRPORTS,
  CONNECTION_STATUS_CONNECTED,
} from 'constants';
import { filtersTemplate } from 'store';
import { cloneObject } from 'utils';
import { Col, Row } from 'react-bootstrap';
import AircraftDetailsPanel from 'components/default/AircraftDetailsPanel';
import MapPanel from 'components/MapPanel';
import AircraftListPanel from './AircraftListPanel';
import AircraftFilterPanel from './AircraftFilterPanel';
import FleetDetailsPanel from './FleetDetailsPanel';
import HealthFilterPanel from './HealthFilterPanel';

import styles from './styles.module.css';

const idPrefix = 'Dashboard';

const Dashboard = (props) => {
  const {
    clearActiveAircraft,
    clearActiveFlight,
    rActiveAircraft,
    rActiveFlight,
    rAircraftFilters,
    rAircraftList,
    rFleetHealth,
    setActiveAircraft,
    setAircraftFilters,
    setActiveFlight,
  } = props;

  const [filteredAircraftList, setFilteredAircraftList] = useState(
    !rAircraftList ? [] : rAircraftList,
  );

  const onFiltersChange = (newFilters) => {
    clearActiveAircraft();
    clearActiveFlight();
    setAircraftFilters(newFilters);
  };

  const applyCurrentFilters = useCallback(
    (newFilters) => {
      const errMessages = [];
      if (
        newFilters.departure_airport &&
        !AIRPORTS[newFilters.departure_airport]
      ) {
        errMessages.push(
          `${newFilters.departure_airport} is an invalid airport code`,
        );
      }
      if (
        newFilters.destination_airport &&
        !AIRPORTS[newFilters.destination_airport]
      ) {
        errMessages.push(
          `${newFilters.destination_airport} is an invalid airport code`,
        );
      }

      // Validation
      //TODO: This will be replaced with styled dialog in the future
      if (errMessages.length) {
        alert(errMessages.join('\n'));
        return;
      }

      const {
        aircraft_status: filteredAircraftStatuses,
        aircraft_type: filteredAircraftType,
        connection_status: filteredConnectionStatus,
        departure_airport: filteredDepartureAirport,
        destination_airport: filteredDestinationAirport,
        flight_status: filteredFlightStatus,
      } = newFilters || {};

      const filteredData = rAircraftList.filter((aircraft) => {
        const flightSlug = aircraft.getCurrentFlight();
        const flight = flightSlug
          ? FlightFactory.getSummaryFlightById(flightSlug.id)
          : undefined;
        const dep = filteredDepartureAirport
          ? AIRPORTS.getEnumerationItem(filteredDepartureAirport)
          : undefined;
        const des = filteredDestinationAirport
          ? AIRPORTS.getEnumerationItem(filteredDestinationAirport)
          : undefined;
        const aircraftStatus = aircraft.status || AIRCRAFT_STATUS_OFFLINE;

        return (
          (!filteredAircraftType ||
            aircraft.aircraft_type.id === filteredAircraftType) &&
          (!filteredFlightStatus ||
            (flight &&
              flight.status &&
              flight.status.id === filteredFlightStatus)) &&
          (!filteredConnectionStatus ||
            (filteredConnectionStatus === CONNECTION_STATUS_CONNECTED.id &&
              aircraft.is_connected) ||
            (filteredConnectionStatus !== CONNECTION_STATUS_CONNECTED.id &&
              !aircraft.is_connected)) &&
          (!dep || (flight && flight.departure_airport.id === dep.id)) &&
          (!des || (flight && flight.destination_airport.id === des.id)) &&
          (!filteredAircraftStatuses ||
            filteredAircraftStatuses.indexOf(aircraftStatus.id) > -1)
        );
      });

      setFilteredAircraftList(filteredData);
    },
    [rAircraftList],
  );

  useEffect(() => {
    if (rActiveFlight && rActiveAircraft) {
      if (
        rActiveAircraft.getCurrentFlight() &&
        rActiveFlight.id !== rActiveAircraft.getCurrentFlight().id
      ) {
        setActiveFlight(rActiveAircraft.getCurrentFlight());
      }
    } else if (!rActiveFlight && rActiveAircraft) {
      const currentFlight = rActiveAircraft.getCurrentFlight();
      if (currentFlight) {
        setActiveFlight(currentFlight);
      }
    }
  }, [rActiveAircraft, rActiveFlight, setActiveFlight]);

  useEffect(() => {
    if (rAircraftList) {
      applyCurrentFilters(rAircraftFilters);
    }
  }, [rAircraftList, applyCurrentFilters, rAircraftFilters]);

  const handleAircraftSelect = (id) => {
    if (!id) return;
    if (!rActiveAircraft || rActiveAircraft.id !== id) {
      const aircraft = rAircraftList.find((ac) => ac.id === id);
      if (!aircraft.is_connected && !rAircraftFilters.disconnected) {
        setAircraftFilters(
          Object.assign({}, rAircraftFilters, { disconnected: true }),
        );
      }
      if (aircraft) {
        setActiveAircraft(aircraft);
      }
      const currentFlight = aircraft.getCurrentFlight();
      if (currentFlight) {
        setActiveFlight(currentFlight);
      } else {
        clearActiveFlight();
      }
    } else {
      clearActiveAircraft();
      clearActiveFlight();
    }
  };

  const fleetHealth =
    JSON.stringify(rAircraftFilters) === JSON.stringify(filtersTemplate)
      ? rFleetHealth
      : null;

  return (
    <Row className={styles.root} data-cy-id={idPrefix} noGutters>
      <Col className={styles.leftPanel}>
        <Row className={styles.filterPanel} noGutters>
          <Col>
            <AircraftFilterPanel
              currentFilters={rAircraftFilters}
              onClearFilters={() =>
                onFiltersChange(cloneObject(filtersTemplate))
              }
              onFiltersApply={(filters) => onFiltersChange(filters)}
              filtersTemplate={filtersTemplate}
            />
          </Col>
        </Row>
        <Row className={styles.healthFilterPanel} noGutters>
          <Col>
            <HealthFilterPanel
              aircraftCount={filteredAircraftList.length}
              currentFilters={rAircraftFilters}
              onDone={(aircraft_status) =>
                onFiltersChange(
                  Object.assign({}, rAircraftFilters, { aircraft_status }),
                )
              }
            />
          </Col>
        </Row>
        <Row className={styles.aircraftList} noGutters>
          <Col className="h-100">
            <AircraftListPanel
              activeAircraft={rActiveAircraft}
              aircraftList={filteredAircraftList}
              onAircraftSelect={handleAircraftSelect}
            />
          </Col>
        </Row>
      </Col>
      <Col md={7} className={styles.mapPanel}>
        <MapPanel
          activeAircraft={rActiveAircraft}
          aircraftList={filteredAircraftList}
          showDisconnected={rAircraftFilters.disconnected}
          onAircraftSelect={handleAircraftSelect}
          onFiltersChange={(disconnected) =>
            onFiltersChange(
              Object.assign({}, rAircraftFilters, { disconnected }),
            )
          }
          activeFlight={rActiveFlight}
          idPrefix={idPrefix}
        />
      </Col>
      <Col className={styles.rightPanel}>
        {!rActiveAircraft ? (
          <FleetDetailsPanel
            aircraftList={filteredAircraftList}
            fleetHealth={fleetHealth}
          />
        ) : (
          <AircraftDetailsPanel
            aircraft={rActiveAircraft}
            flight={rActiveFlight || rActiveAircraft.getCurrentFlight()}
          />
        )}
      </Col>
    </Row>
  );
};

const state = (state) => {
  return {
    rAircraftFilters: state.aircraftFilters,
    rAircraftList: state.aircraftList,
    rActiveAircraft: state.activeAircraft,
    rActiveFlight: state.activeFlight,
    rFleetHealth: state.fleetHealth,
  };
};

const actions = {
  clearActiveAircraft,
  clearActiveFlight,
  setActiveAircraft,
  setAircraftFilters,
  setActiveFlight,
};

export default connect(state, actions)(Dashboard);
