import React from 'react';
import PropTypes from 'prop-types';

import { Aircraft, LRU } from 'models';

import { Row, Col } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const overviewCol = [
  {
    name: 'Tail',
    key: 'tail',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Tail',
  },
  {
    name: 'LRU',
    key: 'lru',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Lru',
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Pos',
  },
  {
    name: 'Bootloader FW',
    key: 'bootloader_fw',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-BootloaderFw',
  },
  {
    name: 'CPU FW',
    key: 'cpu_fw',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-CpuFw',
  },
];

const ContentLoaders = (props) => {
  const { lruData, aircraft } = props;

  const latestLRUHistory = lruData.getLatestHistory();
  let bootloader_fw;
  let cpu_fw;
  if (latestLRUHistory?.software_version) {
    ({
      software_version: { BFW: bootloader_fw, CFW: cpu_fw },
    } = latestLRUHistory);
  }

  const overviewData = [
    {
      tail: aircraft?.tail,
      lru: lruData.lru_type.value,
      pos: lruData.position,
      bootloader_fw: bootloader_fw,
      cpu_fw: cpu_fw,
    },
  ];

  return (
    <Row
      data-cy-id="LruDetailsOverlay-overviewContainer"
      className={styles.overviewContainer}
      noGutters
    >
      <Col>
        <Row>
          <Col
            data-cy-id="LruDetailsOverlay-overviewTableTitle"
            className={styles.title}
          >
            Overview
          </Col>
        </Row>
        <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable columns={overviewCol} rows={overviewData} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ContentLoaders.propTypes = {
  lruData: PropTypes.instanceOf(LRU).isRequired,
  aircraft: PropTypes.instanceOf(Aircraft),
};

export default ContentLoaders;
