// TODO: implement debounce

import { AircraftFactory } from 'classes';
import store from 'store';

export const CLEAR_ACTIVE_AIRCRAFT = 'CLEAR_ACTIVE_AIRCRAFT';
export const FETCH_ACTIVE_AIRCRAFT = 'FETCH_ACTIVE_AIRCRAFT';
export const FETCH_AIRCRAFT_LIST = 'FETCH_AIRCRAFT_LIST';
export const SET_ACTIVE_AIRCRAFT = 'SET_ACTIVE_AIRCRAFT';
export const SET_AIRCRAFT_FILTERS = 'SET_AIRCRAFT_FILTERS';
export const UPDATE_ACTIVE_AIRCRAFT = 'UPDATE_ACTIVE_AIRCRAFT';
export const UPDATE_AIRCRAFT_LIST = 'UPDATE_AIRCRAFT_LIST';

var activeAircraftRequest = null;

export function clearActiveAircraft() {
  return {
    type: CLEAR_ACTIVE_AIRCRAFT,
  };
}

export function setActiveAircraft(aircraft) {
  return async (dispatch) => {
    dispatch({
      type: SET_ACTIVE_AIRCRAFT,
      activeAircraft: aircraft,
    });

    if (activeAircraftRequest) {
      activeAircraftRequest.cancel();
    }

    if (!aircraft) {
      return;
    }

    dispatch({
      type: FETCH_ACTIVE_AIRCRAFT,
    });

    const aid = aircraft.id;
    activeAircraftRequest = AircraftFactory.getAircraftById(aid);
    activeAircraftRequest.then((activeAircraft) => {
      if (activeAircraft) {
        dispatch({
          type: SET_ACTIVE_AIRCRAFT,
          activeAircraft,
        });
      }

      activeAircraftRequest = null;
    });
  };
}

export function updateActiveAircraft() {
  return async (dispatch) => {
    const { activeAircraft: currentAircraft } = store.getState();
    // ignore request if no current active aircraft
    if (!currentAircraft) {
      return;
    }

    if (activeAircraftRequest) {
      activeAircraftRequest.cancel();
    }

    dispatch({
      type: FETCH_ACTIVE_AIRCRAFT,
    });

    const acFetchDt = currentAircraft.modified + 1000;
    const aid = currentAircraft.id;
    activeAircraftRequest = AircraftFactory.getAircraftById(aid, acFetchDt);
    activeAircraftRequest.then((activeAircraft) => {
      if (activeAircraft) {
        dispatch({
          type: UPDATE_ACTIVE_AIRCRAFT,
          activeAircraft,
        });
      }

      activeAircraftRequest = null;
    });
  };
}

export function updateAircraftList() {
  return async (dispatch) => {
    dispatch({
      type: FETCH_AIRCRAFT_LIST,
    });

    let acListFetchDt;
    const { aircraftList: currentAircraftList } = store.getState();
    currentAircraftList?.forEach((aircraft) => {
      if (!acListFetchDt || aircraft.modified > acListFetchDt) {
        acListFetchDt = aircraft.modified + 1000;
      }
    });

    const aircraftList = await AircraftFactory.getAllAircraft(acListFetchDt);

    dispatch({
      type: UPDATE_AIRCRAFT_LIST,
      aircraftList,
    });
  };
}

export function setAircraftFilters(aircraftFilters) {
  return {
    type: SET_AIRCRAFT_FILTERS,
    aircraftFilters,
  };
}
