import { INITIALIZE_APP, UNINITIALIZE_APP } from 'actions';

export function initialized(state = {}, action) {
  switch (action.type) {
    case INITIALIZE_APP:
      return true;
    case UNINITIALIZE_APP:
      return false;
    default:
      return state;
  }
}
