import React, { useState } from 'react';

import {
  AIRCRAFT_TYPE_OPTIONS_W_ALL,
  SELECT_OPTION_ALL,
  PATHS,
} from 'constants';

import { Row, Col, Button } from 'react-bootstrap';
import Select from 'components/inputs/Select';
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const idPrefix = 'LRUFaultContent';

const Content = (props) => {
  const { aircraftList, onFleetRowClick, push } = props;
  const [fleetOption, setFleetOption] = useState(SELECT_OPTION_ALL.value);

  const columnDefinitions = [
    {
      name: 'Tail',
      key: 'tail',
      sortable: true,
      size: 'tiny',
      dataCyClassName: 'LRUFaults-ShipContentListTableColumn-Tail',
    },
    {
      name: 'Fleet',
      key: 'fleet',
      sortable: true,
      size: 'small',
      dataCyClassName: 'LRUFaults-ShipContentListTableColumn-Fleet',
    },
    {
      name: 'Active content set',
      key: 'active_content_set',
      sortable: true,
      showOutliers: fleetOption !== SELECT_OPTION_ALL.value,
      size: 'small',
      dataCyClassName: 'LRUFaults-ShipContentListTableColumn-ActiveContentSet',
    },
    {
      name: 'Staged content set',
      key: 'staged_content_set',
      sortable: true,
      showOutliers: fleetOption !== SELECT_OPTION_ALL.value,
      size: 'small',
      dataCyClassName: 'LRUFaults-ShipContentListTableColumn-StagedContentSet',
    },
    {
      name: 'Active safety video',
      key: 'active_safety_video',
      sortable: true,
      showOutliers: fleetOption !== SELECT_OPTION_ALL.value,
      size: 'small',
      dataCyClassName: 'LRUFaults-ShipContentListTableColumn-ActiveSafetyVideo',
    },
    {
      name: 'Staged safety video',
      key: 'staged_safety_video',
      sortable: true,
      showOutliers: fleetOption !== SELECT_OPTION_ALL.value,
      dataCyClassName: 'LRUFaults-ShipContentListTableColumn-StagedSafetyVideo',
    },
  ];

  const data = aircraftList
    ? aircraftList
        .filter(
          (ac) =>
            fleetOption === SELECT_OPTION_ALL.value ||
            ac.aircraft_type.id === fleetOption,
        )
        .map((ac) => {
          const activeContentset = ac.active_contentset || {};
          const stagedContentset = ac.staged_contentset || {};
          return {
            id: ac.id,
            tail: ac.tail,
            fleet: ac.aircraft_type.value.name,
            active_content_set: activeContentset?.global_id,
            staged_content_set: stagedContentset?.global_id,
            active_safety_video: activeContentset?.safety_video_id,
            staged_safety_video: stagedContentset?.safety_video_id,
          };
        })
    : [];

  const onSelectChange = ({ target: { value } }) => {
    setFleetOption(value);
  };

  return (
    <Row className={styles.root} noGutters>
      <Col>
        <Row className={styles.title} noGutters>
          <Col data-cy-id="LRUFaults-Header" className="title_1">
            Fleet content list
          </Col>
          <Col md="auto">
            <Button
              className="button_reg"
              data-cy-id="LRU-LRUFaultsReportButton"
              onClick={() => push(PATHS.reportLruFaults)}
            >
              LRUs & Faults reports
            </Button>
          </Col>
        </Row>
        <Row className={styles.fleetSelectionContainer} noGutters>
          <Col md="auto">
            <Select
              idPrefix={idPrefix}
              label="Fleet: "
              onChange={onSelectChange}
              options={AIRCRAFT_TYPE_OPTIONS_W_ALL}
              value={fleetOption}
              data-cy-id="LRUFaults-FleetSelect"
            />
          </Col>
        </Row>
        <Row className={styles.tableContainer} noGutters>
          <Col className="h-100">
            <SkyPulseTable
              columns={columnDefinitions}
              rows={data}
              title="Ship - Content list"
              defaultSort="active_content_set"
              defaultSortState="descending"
              onRowClick={(id) => {
                onFleetRowClick(id);
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Content;
